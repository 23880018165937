.start-container {
  background: linear-gradient(122deg, #17181d, #423733);
  display: flex;
  justify-content: center;
  min-height: 100vh;
  .auth-card-container {
    width: 313px;
    height: fit-content;
    margin-top: 20vh;
    .social-buttons,
    .input-container,
    .button,
    .separator {
      margin-top: 20px;
    }
  }

  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .logo-container img {
    height: 40px;
    filter: drop-shadow(0 0 20px #cca57b54);
  }
  .auth-text-container {
    flex-direction: column;
    gap: 0;
    text-align: center;
    .welcome-text {
      font-family: 'Inter';
      color: #8a8a8a;
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      -webkit-text-fill-color: #0000;
      -webkit-background-clip: text;
      background-image: linear-gradient(#ffc17f 20%, rgba(255, 149, 16, 0.549));
      color: #0000;
      display: inline;
      filter: drop-shadow(2px 4px 2px rgb(0, 0, 0));
      opacity: 0.9;
      overflow: visible;
      -webkit-text-shadow: 0 0 20px #cca57b99;
      text-shadow: 0 0 20px #cca57b99;
    }

    h4 {
      margin: 0;
      margin-top: 9px;
      font-weight: 400;
      color: #9e9797;
      font-size: 17px;
      font-family: Rajdhani;
      font-weight: 500;

      a {
        text-decoration: none;
      }
    }
  }

  h4 a {
    color: #ffffffcf;
  }
  .social-buttons {
    display: flex;
    justify-content: space-around;
  }

  .social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    width: 30%;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.5s ease-in-out all;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 9px 20px, rgba(0, 0, 0, 0.3) 0px 5px 15px;

    svg {
      width: 20px;
      height: 20px;
      opacity: 0.9;
      color: #fff;
      transition: 0.5s ease-in-out all;
    }

    &.azure {
      background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Microsoft_Azure.svg/1200px-Microsoft_Azure.svg.png);
      background-size: 26%;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.6) 0px 19px 38px,
        rgba(0, 0, 0, 0.52) 0px 15px 12px;

      svg {
        opacity: 1;
      }
    }
  }

  .separator {
    width: 100%;
    height: 0.5px;
    background: #ffffff26;
    text-align: center;
    color: #ffffff00;
    font-weight: 500;
    font-family: 'Inter';
    font-size: 14px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    gap: 13px;

    input {
      background: #42393266;
      border: 1px solid #ea961a33;
      border-radius: 5px;
      box-sizing: border-box;
      color: #ffffffb3;
      font-family: Inter;
      font-size: 14px;
      font-weight: 300;
      outline: none;
      padding: 10px;
      width: 100%;
      transition: 0.5s ease-in-out all;

      &:focus {
        border-color: #ea971a87;
      }
    }
  }
  .button {
    margin: auto;
    border-radius: 5px;
    text-align: center;
    align-items: center;
    animation: appear 1s ease;
    background: rgba(66, 57, 50, 0.549);
    border: 1px solid rgba(234, 150, 26, 0.529);
    border-radius: 4px;
    color: #d28a42;
    cursor: pointer;
    display: flex;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5));
    font-weight: 600;
    justify-content: center;
    padding: 5px 30px;
    transition: all 0.7s cubic-bezier(0.43, 0.195, 0.02, 1);
    -webkit-user-select: none;
    user-select: none;
    width: -moz-fit-content;
    width: 100%;
    height: 40px;
    max-width: unset;
    font-family: 'Inter';
    box-shadow: rgba(0, 0, 0, 0.1) 0px 19px 38px,
      rgba(0, 0, 0, 0.2) 0px 15px 12px;

    &:hover {
      background: rgba(149, 107, 74, 0.549);
      filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.8));
    }
  }

  .agreement-text {
    font-family: 'Inter';
    font-size: 11px;
    margin-top: 40px; //chanegd
    color: #ffffff78;
    line-height: 1.9;
    span {
      color: #d28a42;
    }
  }

  .error-alert {
    color: #fd7676;
    font-size: 11px;
    display: flex;
    gap: 7px;

    i {
      margin: auto 0;
      font-size: 13px;
    }
  }
}

.element-member {
  position: absolute;
  box-shadow: 0 0 20px 3px #cca57b80;
  height: 50px;
  width: 50px;
  background: #cca57b;
  border-radius: 50%;
  box-shadow: 0 0 20px 10px #cca57b4d;
  display: flex;
  height: 50px;
  justify-content: center;

  .user-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 55%;
    border: none;
    border-radius: 50%;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.2));
    height: 90%;
    margin: auto;
    transition: all 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
    width: 90%;
    background-color: #cca57b;
  }
}

//? Temporary error message style
.error-message {
  color: #d8000c;
  background-color: #ffd2d2;
  border: 1px solid #d8000c;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 14px;
  text-align: left;
  animation: shake 1s ease-in-out;

  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-10px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(10px);
    }
  }
}

.success-message {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid #4f8a10;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 14px;
  text-align: left;
  animation: shake 1s ease-in-out;

  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-10px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(10px);
    }
  }
}
