.notification-panel {
  &.closed {
    transform: translateX(110%);
  }

  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
  position: fixed;
  right: 0;
  width: 316px;
  height: 100vh;
  top: 0;
  background: radial-gradient(
      121.65% 100% at 32.68% 0,
      rgba(59, 57, 57, 0.93) 0,
      rgba(33, 33, 33, 0.68) 32.49%,
      rgba(60, 65, 85, 0) 51.34%
    ),
    radial-gradient(
      91.41% 43.04% at 50% 0,
      rgba(33, 33, 33, 0.556) 20.67%,
      rgba(26, 28, 36, 0) 100%
    ),
    radial-gradient(
      69.96% 25.89% at 50% 100%,
      rgb(33, 33, 33) 22.77%,
      rgb(19, 19, 21) 100%
    );
  z-index: 100;
  border-left: 2px solid #ffffff24;

  .close-button {
    position: absolute;
    left: 0;
    top: 88px;
    transform: translate(-50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #101011;
    border: 2px solid #ffffff24;
    z-index: 1;

    i {
      font-size: 16px;
      opacity: 0.8;
      cursor: pointer;
    }
  }

  .titles-container {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    padding-bottom: 0;
    margin: 0;
    gap: 24px;

    h3 {
      font-size: 16px;
      font-weight: 800;
      margin: 0;
    }

    .tabs {
      width: 100%;
      display: flex;
      font-size: 12px;
      gap: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      align-items: center;
      padding-bottom: 14px;
      border-bottom: 2px solid #ffffff24;
      color: #8a8a8ab5;
      position: relative;

      .tab {
        &.active {
          color: #ffffffc7;
        }

        display: flex;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        gap: 8px;
        box-sizing: border-box;
        margin: 0 4px;

        &-count {
          font-size: 9px;
          padding: 3px 5px;
          background: #ffffffeb;
          color: #101011;
          border-radius: 5px;
          opacity: 0.8;

          &.active {
            background: rgba(31, 33, 37, 0.459);
            color: rgba(204, 165, 123, 0.8);
            border: 1px solid rgba(204, 165, 123, 0.8);
            opacity: 1;
          }
        }

        &-indicator {
          background: #ffffffba;
          position: absolute;
          bottom: -1.9px;
          width: 20%;
          height: 2px;
          left: 0;
          border-radius: 8px;
        }
      }

      .separator {
        width: 1.5px;
        height: 8px;
        background: white;
        opacity: 0.2;
        border-radius: 12px;
      }
    }
  }

  .notifications-scroller {
    overflow-y: scroll;
    max-height: calc(100vh - 106px);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .notification {
    width: 100%;
    padding: 20px 30px;
    display: flex;
    gap: 15px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1.4px solid #ffffff24;

    &-dot {
      position: absolute;
      border-radius: 40px;
      border: 5px solid #cca57b;
      top: 30%;
      left: 0;
      transform: translate(-50%);
      box-shadow: 0 0 11px rgba(204, 165, 123, 1);
    }

    .user-image {
      border: 0;
      min-width: 40px;
      min-height: 40px;
    }

    .notification-content {
      display: flex;
      flex-direction: column;
      gap: 4px;

      p {
        margin: 0;
        font-size: 12px;
        color: #8b8a8a;
        font-weight: 500;

        b {
          color: white;
        }
      }

      .timestamp {
        font-size: 9px;
        color: #8b8a8a;
        font-weight: 500;
      }

      .actions {
        margin-top: 10px;
        display: flex;
        gap: 10px;

        .reaction {
          width: fit-content;
          background: rgba(31, 33, 37, 0.459);
          color: rgba(204, 165, 123, 0.8);
          border-radius: 14px;
          border: 1px solid rgba(204, 165, 123, 0.4);
          padding: 3px 11px;
          display: flex;
          font-size: 10px;
          justify-content: center;
          align-items: center;
          gap: 5px;
          font-weight: 500;
          cursor: pointer;

          &-icon {
            background-size: contain;
            background-position: center;
            width: 13px;
            height: 15px;
            background-repeat: no-repeat;
          }

          &-add {
            background-image: url('https://www.svgrepo.com/show/310820/emoji-add.svg');
            background-size: contain;
            background-position: center;
            width: 16px;
            height: 16px;
            filter: brightness(0) invert(1);
            opacity: 0.8;
            margin: auto 0;
            cursor: pointer;
          }
        }

        .action-button {
          border: 1px solid rgba(109, 105, 105, 0.4);
          background: rgba(78, 84, 95, 0.3);
          cursor: pointer;
          font-size: 10px;
          font-family: 'Poppins', sans-serif;
          color: rgba(255, 255, 255, 0.723);
          font-weight: 500;
          padding: 7px 8px;
          border-radius: 3px;

          &.highlighted {
            color: rgba(204, 165, 123, 0.8);
            box-shadow: inset 1.2000000477px 0 rgba(255, 255, 255, 0.04),
              inset -1.2000000477px 0 rgba(255, 255, 255, 0.04),
              inset 0 -1.2000000477px rgba(255, 255, 255, 0.1),
              inset 0 -0.6000000238px rgba(255, 255, 255, 0.1),
              rgba(0, 0, 0, 0.9) 0px 19px 38px,
              rgba(0, 0, 0, 0.52) 0px 15px 12px;
            border-color: rgba(204, 165, 123, 0.5490196078);
            background: rgba(31, 33, 37, 0.4588235294);
          }
        }
      }
    }
  }
}
