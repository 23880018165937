$breakpoint-one-per-row: 490px;
$breakpoint-sm: 642px;
$breakpoint-md: 750px;
$breakpoint-horizontal-big-card: 900px;
$breakpoint-sidebar-retraction: 1150px;
$breakpoint-xl: 1200px;
$retracted-menu-width: 80px;

//sidebar height
@media only screen and (max-height: 650px) {
  .side-menu {
    .user {
      margin: 0;
    }

    .elements-menu-container {
      .section {
        margin-top: 1rem;
      }

      .menu-element {
        padding: 0.6rem 1.5rem;
      }
    }
  }
}

// sidebar menu retraction
@media only screen and (min-width: $breakpoint-sidebar-retraction) {
  .side-menu .user i.bx {
    display: none;
  }
}

@media only screen and (max-width: $breakpoint-sidebar-retraction) {
  .page-container {
    max-width: calc(100vw - $retracted-menu-width);
  }

  .side-menu {
    width: $retracted-menu-width;
    min-width: $retracted-menu-width;
    padding: 0.5rem;
    gap: 5px;
    align-items: center;

    .logo {
      gap: 0;
      align-items: center;
      padding: 1.2rem 0;

      .logo-image {
        width: 43px;
        height: 35px;
      }

      .logo-name {
        display: none;
        font-size: 1rem;
      }
    }

    .user {
      gap: 0;
      margin: 0;
      padding: 0;
      margin-left: 1rem;

      .user-info {
        display: none;
      }

      .user-image {
        position: relative;

        i {
          opacity: 1;
          position: absolute;
          font-size: 20px;
          left: 80%;
          top: 75%;
          background: #fffffff7;
          border-radius: 50%;
          color: black;
          transform: translate(-14%, -40%);
        }
      }

      i {
        display: none;

        &.bx {
          margin: 0;
          display: initial;
        }
      }
    }

    .elements-menu-container {
      width: 100%;
      gap: 3px;

      .menu-element {
        width: fit-content;
        padding: 1rem;
        gap: 0;

        .text {
          display: none;
        }

        i {
          font-size: 20px;
        }
      }

      .section {
        align-items: center;
        gap: 0;
        margin-top: 0.3rem;

        border-top: 1px solid #a4a3a333;

        &:first-of-type {
          border: unset;
        }

        .section-title {
          opacity: 0;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .header {
    width: calc(100% - $retracted-menu-width);
    left: $retracted-menu-width;
  }
}

@media only screen and (max-width: 425px) {
  .side-menu {
    display: none;
  }
}

// side menu hidden
@media only screen and (max-width: 425px) {
  .page-container {
    max-width: 100vw;
  }

  .side-menu {
    width: $retracted-menu-width;
    min-width: $retracted-menu-width;
    padding: 0.5rem;
    gap: 5px;
    align-items: center;

    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    z-index: 100;
    filter: drop-shadow(2px 4px 6px black);
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) all;
    transform: translateX(-100%);

    &.open {
      transform: translateX(0);
    }

    .logo {
      gap: 0;
      align-items: center;
      padding: 1.2rem 0;

      .logo-image {
        width: 30px;
        height: 30px;
      }

      .logo-name {
        display: none;
        font-size: 1rem;
      }
    }

    .user {
      gap: 0;
      margin: 0;
      padding: 0;
      margin-left: 1rem;

      .user-info {
        display: none;
      }

      i {
        margin: auto;
      }
    }

    .elements-menu-container {
      width: 100%;
      gap: 3px;

      .menu-element {
        width: fit-content;
        padding: 1rem;
        gap: 0;

        .text {
          display: none;
        }

        i {
          font-size: 20px;
        }
      }

      .section {
        align-items: center;
        gap: 0;
        margin-top: 0.3rem;

        border-top: 1px solid #a4a3a333;

        &:first-of-type {
          border: unset;
        }

        .section-title {
          opacity: 0;
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .header {
    width: 100%;
    left: 0;
    padding: 10px;
    gap: 0;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .header .company .company-info {
    display: none;
  }
}

// content
.content {
  // 1280 --> 900px
  @media only screen and (max-width: 1280px) and (min-width: $breakpoint-horizontal-big-card) {
    padding-left: 3vw;
    padding-right: 0.5rem;
  }

  @media only screen and (max-width: $breakpoint-horizontal-big-card) and (min-width: $breakpoint-md) {
    padding-left: calc(2rem + 2vw);
    padding-right: 2rem;
  }

  // 750 --> 642
  @media only screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
    padding-left: 2vw;
  }

  @media only screen and (max-width: $breakpoint-md) {
    padding-right: 2vw;
  }

  @media only screen and (max-width: $breakpoint-one-per-row) {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

// cards
//1337px --> 1280px
@media only screen and (max-width: 1337px) and (min-width: 1280px) {
  .dashboard-grid {
    padding-right: 0;

    .widgets {
      .card,
      .empty-card {
        min-width: 220px;

        .card-content {
          gap: 0.2rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
}

// 1280px --> 900px
@media only screen and (max-width: 1280px) and (min-width: $breakpoint-horizontal-big-card) {
  .dashboard-grid .widgets {
    .card,
    .empty-card {
      padding: 0.9rem;
      max-width: 255px;
      min-width: 220px;

      .card-header {
        gap: 5px;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          min-width: 20px;
        }

        i {
          font-size: 20px;
          margin-right: 5px;
        }

        .subtitle {
          font-size: 10px;
        }
      }

      .card-content {
        .metric {
          font-size: 18px;

          span {
            font-size: 22px;
          }
        }

        .label {
          font-size: 11px;

          .rate {
            gap: 3px;
          }
        }
      }

      .card-footer {
        margin-top: 0;
      }
    }
  }
}

// 900 --> 750
@media only screen and (max-width: $breakpoint-horizontal-big-card) and (min-width: $breakpoint-md) {
  .dashboard-grid .widgets {
    .card,
    .empty-card {
      max-width: 276px;
    }
  }
}

// 750px --> 642px
@media only screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
  .dashboard-grid .widgets {
    .empty-card,
    .card {
      min-width: 220px;
      // padding: 0.9rem;
      max-width: 255px;

      .card-header {
        gap: 5px;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          min-width: 20px;
        }

        i {
          font-size: 20px;
          margin-right: 5px;
        }

        .subtitle {
          font-size: 10px;
        }
      }

      .card-content {
        gap: 0.2rem;
        margin-top: 0.5rem;

        .metric {
          font-size: 18px;

          span {
            font-size: 22px;
          }
        }

        .label {
          font-size: 11px;

          .rate {
            gap: 3px;
          }
        }
      }

      .card-footer {
        margin-top: 0;
      }
    }
  }
}

// 750 --> 642
@media only screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
  .dashboard-grid .widgets {
    .empty-card,
    .card {
      min-width: 230px;
      padding: 0.9rem;
    }
  }
}

// 642 --> 490
@media only screen and (max-width: $breakpoint-sm) and (min-width: $breakpoint-one-per-row) {
  .dashboard-grid .widgets {
    .card,
    .empty-card {
      padding: 0.7rem;
      min-width: 190px;
      height: 175px;
      width: 90%;

      .card-header {
        gap: 5px;

        .icon {
          width: 20px;
          height: 20px;
          margin-right: 2px;
          min-width: 20px;
        }

        i {
          font-size: 20px;
          margin-right: 5px;
        }

        .subtitle {
          font-size: 10px;
        }
      }

      .card-content {
        margin-top: 1rem;

        .metric {
          font-size: 17px;

          span {
            font-size: 20px;
          }
        }

        .label {
          font-size: 10px;

          .rate {
            gap: 3px;
          }
        }
      }

      .card-footer {
        margin-top: 0;
      }
    }
  }
}

// <490
@media only screen and (max-width: $breakpoint-one-per-row) {
  .dashboard-grid {
    align-items: center;

    .widgets {
      width: 100%;

      .empty-card,
      .card {
        width: 100%;
      }
    }

    .widgets > * {
      flex-direction: column !important;
      padding: 0 5%;
    }

    .widgets > * > * {
      width: 100%;
    }
  }
}

//big-card
.dashboard-grid {
  @media only screen and (max-width: $breakpoint-horizontal-big-card) {
    &:not(.most-used-software) {
      display: flex;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 1280px) and (min-width: $breakpoint-horizontal-big-card) {
    .big-card {
      padding: 1rem 1.5rem;
      min-width: 290px;
      height: 540px;

      .card-header {
        margin-top: 0.5rem;

        .title {
          font-size: 20px;
          font-weight: 400;
        }
      }

      .card-content {
        margin-top: 1.5rem;
      }
    }

    .goals-container {
      min-height: 240px;

      // min-width: 200px;
      .goal-card {
        gap: 0.5rem;
        padding: 0.875rem 0.75rem;

        .value {
          font-size: x-large;
          gap: 5px;

          span {
            font-size: medium;
          }
        }

        .footer {
          p {
            font-size: x-small;
          }

          .icons {
            font-size: 28px;
          }
        }
      }
    }

    .progress-bar-container {
      .text {
        font-size: 10px;
      }
    }

    .events-container {
      gap: 0.5rem;

      p {
        font-size: 18px;
        margin: 10px 0;
      }

      .events-scroll {
        height: 164px;
      }

      .event-row {
        gap: 1rem;
        font-size: 12px;

        .event-image {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  // 900 -->
  @media only screen and (max-width: $breakpoint-horizontal-big-card) and (min-width: $breakpoint-sm) {
    .content {
      padding: 0rem 4rem;
    }

    .big-card {
      width: 75vw;
      height: fit-content;
      padding: 1.5rem;
      justify-content: unset;

      .card-header {
        margin-top: 0;
        margin-bottom: 16px;
      }

      .card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 5%;
        margin-top: 0;

        .progress-bar-container {
          .text {
            display: block;
          }
        }
      }
    }

    .goals-container {
      flex: 2;
      max-width: 280px;
    }
  }

  @media only screen and (max-width: $breakpoint-sm) and (min-width: $breakpoint-one-per-row) {
    .big-card {
      width: 95%;
      padding: 1rem;
      height: fit-content;

      .card-header {
        margin-top: 0;
        margin-bottom: 16px;
      }

      .card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: 5%;
        margin-top: 0;

        .progress-bar-container {
          .text {
            display: block;
          }
        }
      }

      .events-container {
        gap: 0.5rem;

        p {
          font-size: 18px;
          margin: 10px 0;
        }

        .events-scroll {
          height: 152px;
        }

        .event-row {
          gap: 0.5rem;
          font-size: 12px;

          .event-image {
            width: 35px;
            height: 35px;
          }
        }
      }

      .goals-container {
        flex: 2;
        max-width: 230px;

        .goal-card {
          gap: 0.5rem;
          padding: 0.875rem 0.75rem;

          .value {
            font-size: x-large;
            gap: 5px;

            span {
              font-size: medium;
            }
          }

          .footer {
            p {
              font-size: x-small;
            }

            .icons {
              font-size: 26px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-one-per-row) {
    .big-card {
      padding: 1rem 1.5rem;
      min-width: 90%;
      height: 600px;
      align-self: center;

      .goals-container .goal-card .value {
        font-size: xx-large;
        gap: 5px;

        span {
          font-size: medium;
        }
      }
    }
  }
}

// dashboard element
.dashboard-element {
  @media only screen and (min-width: 1200px) {
    padding-right: 1rem;
  }

  .table {
    .row {
      align-items: center;

      @media only screen and (max-width: 1280px) {
        padding-right: 0;
        padding-left: 15px;

        .element {
          font-size: 13px;
        }


        &:last-child {
          flex: 0.5;
        }
      }

      @media only screen and (max-width: 750px) {
        padding-left: 10px;
      }

      @media only screen and (max-width: 642px) {
        .main {

          .software-name {
            display: none;
          }
        }
      }

      @media only screen and (max-width: 490px) {
        .element {
          font-size: 12px;
        }

        .element {
          .icon {
            width: 25px;
            height: 25px;
          }

          i {
            margin: 0;
          }
        }

        .owner {
          align-items: center;
          flex: 0.5;

          .owner-name {
            display: none;
          }
        }
      }
    }
  }
}

//modal
.modal-group {
  .modal-container {
    @media only screen and (max-width: 1380px) {
      width: 60%;
    }

    @media only screen and (max-width: 1100px) {
      width: 80vw;
    }

    .modal {
      @media only screen and (max-width: 530px) and (min-width: 475px) {
        padding: 40px 30px;
      }
    }

    .modal-content {
      @media only screen and (max-width: 757px) {
        &.add-team-modal {
          form {
            width: 40%;
          }
      }
      }
      @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.add-team-modal {
            display: flex;
            flex-direction: column;
            gap: 10px;
            form, .team-card {
              width: 100%;
            }
        }
      }

      .search-bar-container {
        @media only screen and (max-width: 600px) {
          flex-direction: column;
          width: 100%;
          gap: 9px;
        }
      }

      .search-content {
        @media only screen and (max-width: 710px) {
          justify-content: center;
        }

        .mini-software-card {
          @media only screen and (max-width: 1200px) and (min-width: 800px) {
            height: 190px;
          }

          @media only screen and (max-width: 800px) and (min-width: 475px) {
            width: calc(50% - 10px);
            // padding: 15px 12px;
            height: unset;
          }

          @media only screen and (max-width: 475px) {
            width: 100%;
            padding: 15px;
            height: unset;
          }

          // p {
          //   @media only screen and (max-width: 475px) {
          //     font-size: 12px;
          //   }
          // }
        }
      }

      .teams-content {
        @media only screen and (max-width: 440px) {
          justify-content: center;
        }

        @media only screen and (max-width: 850px) {
          .team-card {
            width: calc(50% - 10px);
          }
        }

        @media only screen and (max-width: 500px) {
          .team-card {
            width: 100%;
            height: 230px;
          }
        }
      }
    }
  }
}

//team overview
@media only screen and (max-width: 1400px) {
  .dashboard-grid.team-overview .container {
    transform: scale(0.9);
  }
}

@media only screen and (max-width: $breakpoint-horizontal-big-card) {
  .dashboard-grid.team-overview {
    gap: 180px;
    height: unset;

    .container {
      transform: scale(0.75) translateY(52%);

      &.team-container {
        transform: unset;
        width: 100%;

        .team-row .team-stats {
          padding-top: 0;
        }
      }

      .percentage-metric {
        transform: scale(1.3);
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .dashboard-grid.team-overview {
    .container {
      transform: scale(0.65) translateY(45%);

      &.team-container {
        transform: unset;
        width: 100%;
        margin: 0;
      }
    }
  }
}

//saas elements
@media only screen and (max-width: 1500px) {
  .section-content {
    .saas-elements-container {
      .row {
        gap: 20px;
        flex-wrap: nowrap;

        .saas-element {
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1320px) and (min-width: $breakpoint-sidebar-retraction) {
  .section-content {
    .saas-elements-container {
      transform: translate(-24%, -29%) scale(0.8);
    }

    .table-rankings-preview-container {
      transform: translate(20%, 15%) scale(0.9);
    }
  }
}

@media only screen and (max-width: $breakpoint-sidebar-retraction) and (min-width: 975px) {
  .section-content .saas-elements-container {
    transform: translate(-16%, -24%) scale(0.98);

    .row {
      gap: 15px;

      .saas-element {
        width: 110px !important;
        height: 110px !important;
        gap: 1.3rem;
      }
    }
  }
}

@media only screen and (max-width: 975px) and (min-width: 900px) {
  .section-content .saas-elements-container {
    transform: translate(-16%, -24%) scale(0.9);
  }

  .table-rankings-preview-container {
    transform: translate(20%, 15%) scale(0.9);
  }
}

@media only screen and (max-width: 900px) {
  .section-content {
    &.dashboard-grid {
      justify-items: center;
    }

    .saas-elements-container {
      width: 100%;
      transform: translate(0%, -20%);
      justify-content: center;

      .row {
        gap: 30px;
      }
    }

    .table-rankings-preview-container {
      align-self: center;
      right: unset;
      transform: translate(0, 150%);
    }
  }
}

@media only screen and (max-width: 700px) {
  .section-content .saas-elements-container {
    transform: translate(0%, -20%) scale(0.85);
  }
}

@media only screen and (max-width: 500px) {
  .section-content {
    .saas-elements-container {
      transform: translate(0%, -28%) scale(0.75);
    }

    .table-rankings-preview-container {
      transform: translate(0, 100%) scale(0.85);
    }
  }
}

// invoices
.invoices-container {
  .options-bar {
    @media only screen and (max-width: 950px) {
      padding-right: 0;
    }

    &:not(.filters) {
      @media only screen and (max-width: 565px) {
        flex-direction: column;
        align-items: center;

        .option {
          max-width: unset;
          width: 95%;

          &.upload {
            max-width: unset;
            width: 95%;
          }
        }
      }
    }
  }

  .files-gallery {
    @media only screen and (max-width: 1200px) and (min-width: 810px) {
      justify-content: flex-start;
      // padding-right: 2rem;
    }

    @media only screen and (max-width: 790px) {
      .file-container {
        width: 48%;
      }
    }

    @media only screen and (max-width: 515px) {
      justify-content: center;
    }

    @media only screen and (max-width: 515px) {
      .file-container {
        max-width: unset;
        width: 100%;
      }
    }
  }

  .options-bar-container {
    @media only screen and (max-width: 550px) {
      flex-direction: column;

      .options-bar {
        gap: 0;
        padding-right: 0;
        width: 100%;

        .filter i {
          font-size: 20px !important;
        }
      }

      .search-bar {
        margin-left: auto;
        min-width: 180px;
      }
    }
  }

  .files-list {
    @media only screen and (max-width: 950px) {
      padding-right: 0;
    }

    .table {
      @media only screen and (max-width: 1380px) {
        .table-header {
          display: flex;
          align-items: center;

          div {
            display: flex;
            align-items: center;
          }

          div:nth-last-child(-n + 3) {
            flex: 0.5;
            font-size: 11px;
          }
        }

        .row {
          .element:nth-last-child(-n + 3) {
            flex: 0.5;
          }
        }
      }

      @media only screen and (max-width: 1380px) and (min-width: $breakpoint-sidebar-retraction) {
        .table-header {
          padding-right: 10px;
        }

        .row {
          padding-left: 22px;
          padding-right: 10px;
        }
      }

      @media only screen and (max-width: 950px) {
        .table-header {
          padding: 15px 10px;

          div {
            font-size: 11px;
          }
        }

        .row {
          padding: 15px 10px;
        }
      }

      @media only screen and (max-width: 815px) {
        .table-header {
          div:nth-last-child(-n + 3) {
            flex: 1;
          }

          div:first-of-type {
            width: 25%;
          }

          div:nth-child(3),
          div:nth-child(4) {
            width: 15%;
          }
        }

        .row {
          .element:nth-last-child(-n + 3) {
            flex: 1;
          }

          .element:first-of-type {
            width: 25%;

            .preview {
              display: none;
            }
          }

          .element:nth-child(2),
          .element:nth-child(3) {
            justify-content: center;

            .avatar {
              width: 25px;
              height: 25px;
            }

            div:last-child {
              display: none;
            }
          }

          .element:nth-child(4),
          .element:nth-child(3) {
            width: 15%;
          }
        }
      }

      @media only screen and (max-width: 615px) {
        .table-header {
          div:nth-last-child(-n + 3),
          div {
            font-size: 10px;
          }
        }

        .row {
          h3 {
            font-size: 12px;
          }

          .element {
            font-size: 12px;
          }
        }
      }

      @media only screen and (max-width: 490px) {
        .table-header {
          padding-left: 5px;

          div:first-of-type {
            width: 20%;
          }
        }

        .row {
          padding-left: 5px;
          margin: auto 0;

          .element:first-of-type {
            width: 20%;
          }

          h3 {
            white-space: initial;
          }

          .element {
            font-size: 11px;
          }
        }
      }

      @media only screen and (max-width: 490px) and (min-width: 425px) {
        .table-header {
          div {
            flex-wrap: wrap;
            justify-content: center;
          }
        }

        .row {
          .element {
            width: 10%;
          }

          .element:first-of-type {
            width: 15%;
          }
        }
      }
    }
  }
}

//pagination container
.pagination-container {
  margin: 0;

  @media only screen and (max-width: 790px) {
    &.padding {
      padding: 0;
    }

    ul {
      padding-inline-start: 0;
    }
  }
}

//teams
.teams-cards-container {
  @media only screen and (max-width: 1470px) and (min-width: 555px) {
    .team-card {
      width: calc(33.33% - 15px);
    }
  }

  @media only screen and (max-width: 1255px) and (min-width: 555px) {
    .team-card {
      .team-card-body {
        .info-section {
          flex-direction: column;

          .stats:last-child {
            align-self: flex-end;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 950px) and (min-width: 555px) {
    .team-card {
      padding: 25px;

      .team-card-header .title h3 {
        font-size: 25px;
      }
    }
  }

  @media only screen and (max-width: 880px) and (min-width: 555px) {
    gap: 10px;

    .team-card {
      width: calc(50% - 10px);

      .team-card-header .title h3 {
        font-size: 22px;
      }
    }
  }

  @media only screen and (max-width: 555px) {
    justify-content: center;

    .team-card {
      width: 100%;
    }
  }
}

.table-container {
  @media only screen and (max-width: 1500px) {
    padding-right: 0;

    .table-header {
      div:first-of-type,
      div {
        width: 25% !important;

        &.no-width {
          width: 0% !important;
        }
      }
    }

    .table .row .element:first-of-type {
      width: 25%;
    }

    .row:not(.add) {
      .element,
      .element:first-of-type {
        width: 25% !important;

        &.options {
          width: 5% !important;
        }

        &.no-width {
          width: 0% !important;   
          position: relative;
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) and (min-width: $breakpoint-sidebar-retraction) {
    .table-header,
    .row:not(.add) {
      padding: 20px 15px;
    }

    .row .element {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 980px) {
    .table-header {
      display: flex;
      align-items: center;

      div {
        display: flex;
        align-items: center;
      }

      div:nth-last-child(-n + 2) {
        justify-content: flex-end;
        flex: 0.5;
        font-size: 11px;
      }
    }

    .row:not(.add) {
      .element:nth-last-child(-n + 2) {
        justify-content: flex-end;
        flex: 0.5;

        i {
          margin-left: 5px;
        }
      }
    }
  }

  @media only screen and (max-width: 815px) {
    .table .table-header,
    .row:not(.add) {
      padding: 20px 15px;
    }

    .row .element {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 590px) {
    .table-header {
      padding: 15px 7px;

      div {
        flex-wrap: wrap;
        width: 20% !important;
      }

      div:nth-child(2) {
        width: 30% !important;
      }

      div:nth-last-child(-n + 2) {
        justify-content: flex-start;
        font-size: 10px;

        .icon {
          margin: 0;
        }
      }
    }

    .row:not(.add) {
      .element {
        width: 20% !important;

        div {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .element:nth-child(2) {
        width: 30% !important;
      }

      .element {
        .avatar {
          display: none;
        }
      }
    }
  }
}

//providers
.providers-container {
  @media only screen and (max-width: 1450px) and (min-width: 760px) {
    .providers-cards-container {
      gap: 0;

      .providers-cards {
        column-gap: 0;

        .provider-card {
          transform: scale(0.95);
          aspect-ratio: initial;
        }
      }

      .selected-provider {
        margin: 0;
        transform: scale(0.95);

        .row .value {
          font-size: 17px;
        }
      }
    }
  }

  @media only screen and (max-width: 760px) {
    justify-content: flex-start;
    padding-top: 1rem;

    .providers-cards-container {
      gap: 2rem;
      display: flex;
      flex-direction: column-reverse;

      .providers-cards {
        gap: 1.5rem;
      }

      .selected-provider {
        margin: 0;
      }
    }
  }

  @media only screen and (max-width: 580px) {
    .providers-cards-container {
      .providers-cards .provider-card {
        .card-info .card-title {
          font-size: 14px;
        }

        .card-image {
          width: 30%;
          height: 40%;
        }

        aspect-ratio: initial;
      }

      .selected-provider .row .value {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .analytics-page {
    .stats-cards-container {
      .stats-card {
        width: 100%;
      }
    }
  }

  .application-page {
    .stats-cards-container {
      flex-direction: column;

      .stats-card {
        margin-bottom: 10px;
      }
    }
  }

  .applications-container {
    padding: 2rem;

    &.invoices-container {
      padding: 0;
    }
  }
}

.budget {
  @media only screen and (min-width: 580px) and (max-width: 900px) {
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .notification-panel {
    display: none;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .applications-container {
    .application-page {
      .software-card {
        height: fit-content;

        .card-content {
          padding: 0;
          flex-wrap: wrap;
          gap: 10px;

          .detail {
            width: 48%;
          }
        }
      }
    }
  }

}
