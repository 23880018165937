@keyframes reknah-card-spark {
  0% {
    box-shadow: rgb(204 165 123 / 25%) 0px 0px 4px 0px,
      rgb(255 151 40 / 25%) 0px 0px 16px 0px;
  }

  33% {
    box-shadow: rgb(204 165 123 / 35%) 3px 5px 4px 0px,
      rgb(255 151 40 / 35%) 0px 0px 16px 0px;
  }

  66% {
    box-shadow: rgb(204 165 123 / 45%) -3px 0px 4px 0px,
      rgb(255 151 40 / 45%) 0px 0px 16px 0px;
  }

  100% {
    box-shadow: rgb(204 165 123 / 25%) 0px 0px 4px 0px,
      rgb(255 151 40 / 25%) 0px 0px 16px 0px;
  }
}

@keyframes reknah-card-idle-initial {
  0% {
    box-shadow: rgb(204 165 123 / 25%) 0px 0px 4px 0px,
      rgb(255 151 40 / 25%) 0px 0px 16px 0px;
  }

  50% {
    box-shadow: rgb(204 165 123 / 35%) 0px 0px 4px 0px,
      rgb(255 151 40 / 45%) 0px 0px 16px 0px;
  }

  100% {
    box-shadow: rgb(204 165 123 / 25%) 0px 0px 4px 0px,
      rgb(255 151 40 / 25%) 0px 0px 16px 0px;
  }
}

@keyframes reknah-card-idle-initial {
  0% {
    box-shadow: rgb(204 165 123 / 25%) 0px 0px 4px 0px,
      rgb(255 151 40 / 25%) 0px 0px 16px 0px;
  }

  50% {
    box-shadow: rgb(204 165 123 / 35%) 0px 0px 4px 0px,
      rgb(255 151 40 / 45%) 0px 0px 16px 0px;
  }

  100% {
    box-shadow: rgb(204 165 123 / 25%) 0px 0px 4px 0px,
      rgb(255 151 40 / 25%) 0px 0px 16px 0px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotation-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-20%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes appear-left-right {
  from {
    opacity: 0;
    transform: translateX(-20%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
