.profile_page {
  .profile_card {
    margin-top: 4vh;
    min-height: 300px;
    font-family: $secondary-font;
    background: radial-gradient(
      113.03929211915995% 100% at 50.000010106500284% -1.3322676295501878e-13%,
      #181818 0%,
      rgba(25, 25, 25, 1) 0.009999999747378752%,
      hsla(0, 0%, 3%, 0.81) 100%
    );
    border-radius: 15px;
    border-radius: 15px 15px 5px 15px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 -1.2000000477px rgb(255 255 255 / 10%),
      inset 0 -0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 1.2000000477px rgb(255 255 255 / 10%),
      inset 0 0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    position: relative;
    box-sizing: border-box;
    padding: 30px;
    cursor: pointer;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
    color: #e2e8ff;
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-right: 25px;
    justify-content: space-between;
    width: 100%;

    box-sizing: border-box;
    align-items: center;
    display: flex;
    flex-direction: unset;
    min-height: unset;
    height: 193px;

    .profile_picture_container {
      .picture_container {
        width: fit-content;
        height: fit-content;
        border-radius: 50%;
        padding: 3.5px;
        box-shadow: 0 0 12px 4px rgba(204, 165, 123, 0.5);
        margin: auto;
        border: 3px solid #cca57be6;

        .user-image {
          width: 70px;
          height: 70px;
          border: unset;
        }
      }

      h3 {
        font-size: 13px;
        font-family: 'Inter';
        margin: auto;
        margin-top: 16px;
        text-align: center;
        text-transform: capitalize;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
          opacity: 0.8;
        }
      }

      h4 {
        font-size: 10px;
        font-family: 'Inter';
        margin: auto;
        font-weight: 300;
        text-align: center;
        opacity: 0.6;
        margin-top: 5px;
        text-transform: capitalize;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .card-content {
      height: 100%;
      box-sizing: border-box;
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .details {
        display: flex;
        gap: 10%;

        &.second_row {
          display: flex;
          gap: 10%;
          justify-content: space-between;
        }

        .detail {
          height: fit-content;

          &.button-container {
            justify-content: center;
            display: flex;

            .button {
              margin: auto;
              font-size: 12px;
              box-shadow: 0 0 20px 10px rgba(204, 165, 123, 0.1);
              background: linear-gradient(
                227deg,
                #f5ae60 0%,
                rgb(110, 84, 70) 100%
              );
              border-radius: 15px;
              padding: 10px 25px;
              box-sizing: border-box;
              font-weight: 500;
              font-family: 'Rajdhani';
              color: #eee9e9f5;
              display: flex;
              align-items: center;
              border: unset;

              i {
                margin-left: 5px;
              }
            }
          }

          .label {
            font-size: 10px;
            font-weight: 300;
            opacity: 0.5;
          }

          &.teams {
            .label {
              font-size: 10px;
              font-weight: 300;
              opacity: 0.5;
              width: fit-content;
            }

            .category {
              font-size: 10px;

              &.selected {
                color: #ffc17f;
                border-color: #ffc17f;
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}
