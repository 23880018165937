$primary-font: 'Inter', sans-serif;
$secondary-font: 'Poppins', sans-serif;

.alert-container {
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: 9999;
    width: 300px;
    padding: 0 15px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 15px;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: inset 1.2000000477px 0 rgba(255, 255, 255, 0.04), inset -1.2000000477px 0 rgba(255, 255, 255, 0.04), inset 0 1.2000000477px rgba(255, 255, 255, 0.1), inset 0 0.6000000238px rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.9) 0px 19px 38px, rgba(0, 0, 0, 0.52) 0px 15px 12px;
    animation: appear 0.5s ease-in-out;

    @media only screen and (max-width: 370px) {
        width: 93vw;
        left: 10px;
        box-sizing: border-box;
    }
   
    &.line-red {
        background-color: rgb(126 6 19 / 95%);
        box-shadow: 0 0 20px 1px rgb(91 9 18 / 77%);
    }
    
    &.line-green {
        background-color: rgb(44 130 80 / 95%);
        box-shadow: 0 0 20px 1px #3cb46ecc;

        i {
            font-size: 18px;
        }
    }
    
    &.line-blue {
        background-color: #0091ffcc;
        box-shadow: 0 0 20px 1px #0091ffa0;

        i {
            font-size: 19px;
        }
    }

    .text {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    
    .alert-content {
        display: inline-block;
        padding-left: 10px;
        overflow-wrap: break-word;
        overflow-x: hidden;
    }
    
    .alert-close {
        display: inline-block;
        text-align: center;
        cursor: pointer;
        font-size: 18px;
    }
}

