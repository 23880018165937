.applications-container {
  padding-right: 4rem;

  .application-page {
    padding-top: 2rem;
    .software-card {
      width: 100%;
      width: 97%;
      margin: auto;
    }

    .table-container {
      padding: 0
    }
  }
}

.stats-cards-container {
  display: flex;
  gap: 2%;

  .stats-card {
    width: 47%;
    min-height: 370px;

    .title {
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.team-component-section {
  .most-used-software {
    padding-top: 1vw;
  }
}
