$primary-font: 'Inter', sans-serif;
$secondary-font: 'Poppins', sans-serif;
$orange: #ea961a;

.invoices-container {
  h3 {
    font-size: 16px;
  }

  .scrollable-container {
    transform: translateX(-4vw);
    max-width: calc(100% + 4vw);
    width: calc(100% + 4vw);

    .scroll {
      padding-left: 4vw;
    }
  }
}

.software-selection {
  cursor: pointer;

  div {
    max-width: 75px !important;
  }
}

.options-bar-container {
  display: flex;
  justify-content: space-between;

  h3 {
    margin: auto;
    margin-left: 0;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.options-bar {
  padding: 10px 0;
  display: flex;
  gap: 15px;

  .option {
    border: 1px solid;
    background: linear-gradient(167deg, #2c2d30, #ffffff00);
    border-color: #aaa7a7a6;
    padding: 20px 35px;
    border-radius: 15px;
    max-width: 300px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;

    background: radial-gradient(
      113.0392921192% 100% at 50.0000101065% 0%,
      #181818 0%,
      rgb(25, 25, 25) 0.0099999997%,
      hsla(0, 0%, 3%, 0.81) 100%
    );
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 1.2000000477px rgb(255 255 255 / 10%),
      inset 0 0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    border-radius: 15px 15px 5px 15px;
    border: 1px solid rgba(255, 255, 255, 0.07);

    &:hover {
      box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
        inset -1.2000000477px 0 rgb(255 255 255 / 4%),
        inset 0 -1.2000000477px rgb(255 255 255 / 10%),
        inset 0 -0.6000000238px rgb(255 255 255 / 10%),
        rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
      border: 1px solid;
      background: linear-gradient(167deg, #2c2d30, #ffffff00);
      border-color: #aaa7a7a6;
    }

    div {
      display: flex;

      i {
        font-size: 24px;
        opacity: 0.8;
        transform: translateX(-3px);
        margin: auto 0;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        opacity: 0.8;
        margin: auto 0;
        font-family: $secondary-font;
      }
    }

    p {
      margin: 0;
      opacity: 0.5;
      font-size: 12px;
      margin-top: 2px;
      font-weight: 300;
    }

    &.upload {
      border-style: dashed;
      text-align: center;
      max-width: unset;
      width: 70%;
      align-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-color: #aaa7a751;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 15px;
        background-image: url(https://c02.purpledshub.com/uploads/sites/41/2021/08/mountains-7ddde89.jpg);
        background-image: url(https://images.pexels.com/photos/3894157/pexels-photo-3894157.jpeg?auto=compress&cs=tinysrgb&w=1600);
        background-image: url(https://images.pexels.com/photos/10335094/pexels-photo-10335094.jpeg?auto=compress&cs=tinysrgb&w=1860&dpr=1);
        background-image: url(https://images.pexels.com/photos/10919581/pexels-photo-10919581.jpeg?auto=compress&cs=tinysrgb&w=1860&dpr=1);
        /* background-image: url(https://images.pexels.com/photos/2978800/pexels-photo-2978800.jpeg?auto=compress&w=1600); */
        background-position: center;
        mix-blend-mode: soft-light;
        opacity: 0.3;
      }

      div {
        width: fit-content;
        margin: 0 auto;

        i {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }
  }

  &.filters {
    justify-content: end;
    padding-right: 2vw;

    .filter {
      padding: 10px;
      border-radius: 5px;
      border: 1.5px solid #afa9a980;
      display: flex;
      border: none;
      background: linear-gradient(167deg, #2c2d30, rgba(255, 255, 255, 0));
      border-color: rgb(170 167 167 / 32%);
      cursor: pointer;
      transition: 0.75s ease all;
      background: none;

      &.alternative {
        background: none;
      }

      &.selected {
        i {
          color: #fff;
        }
      }

      i {
        margin: auto;
        font-size: 19px;
        transition: 0.75s ease all;
      }

      &:hover {
        i {
          color: #fff;
        }
      }
    }
  }
}

.scrollable-container {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;

  .scroll {
    width: fit-content;
    display: flex;
    padding: 10px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.file-container,
.folder-container,
.recent-file-container {
  width: 300px;
  height: 75px;
  display: flex;
  margin-right: 10px;
  border: 1px solid #6d696966;
  border-radius: 5px;
  background: #1f2125;
  display: flex;
  padding: 12px;
  box-sizing: border-box;
  transition: 0.4s ease-in-out all;
  cursor: pointer;
  overflow: hidden;

  position: relative;
  overflow: unset;
  
  &:hover {
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 -1.2000000477px rgb(255 255 255 / 10%),
      inset 0 -0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    border-color: #cca57b8c;
    background: #1f212575;

    .preview {
      background: #cca57ba3;
    }
  }

  .preview {
    width: 25%;
    background: #4e545f94;
    border-radius: 5px;
    display: flex;
    width: 50px;
    height: 50px;
    transition: 0.6s ease-in-out all;

    .icon {
      margin: auto;
      width: 20px;
      height: 26px;
      background-size: contain;
      filter: brightness(0) invert(1);
    }

    i {
      margin: auto;
      color: white;
      opacity: 0.9;
      font-size: 20px;
      transition: 0.6s ease-in-out all;
    }
  }

  .file-details,
  .folder-content,
  .recent-file-content {
    width: 60%;
    height: fit-content;
    margin: auto;
    padding-left: 20px;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
    animation: appear .5s ease-in-out;

    h3,
    p {
      margin: 0;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      margin-top: 3px;
      transition: 0.6s ease-in-out all;
      animation: appear 1.75s ease-in-out;
    }

    .folder-input {
      width: 100%;
      box-shadow: none;
      background: transparent;
      border: none;
      font-weight: 400;
      font-size: 14px;
      font-family: $primary-font;
      padding: 0;
      animation: appear 1.55s ease-in-out;
    }
  }

  .options-container {
    display: flex;

    i {
      margin: auto;
      font-size: 17px;
      margin-top: 8px;
      cursor: pointer;
      transition: 0.4s ease-in-out all;

      &:hover {
        color: #d4d4d4;
      }
    }
  }

  .dropdown-menu {
    right: 10%;
    ul {
      width: 100%;

      li {
        padding: 8px 12px;
      }
    }
  }
}

.folder-container {
  padding: 0;
  transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
  padding-right: 12px;

  &:nth-of-type(even) {
    .preview {
      transform: translateX(-1px);
    }
  }

  .preview {
    height: 100%;
    width: 30%;
    border-radius: 4px 0 0 4px;
    max-width: 87px;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;

    i,
    .icon {
      width: 35px;
      height: 35px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .options-container {
    i {
      margin: auto;
      margin-top: 15px;
    }
  }

  &.add {
    width: 84px;
    padding-right: 0;

    .folder-content {
      padding: 0;
      width: 0;
      margin: 0;
    }

    .preview {
      width: 87px;
    }
  }
}

.files-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  padding: 10px 0;
}

.files-list,
.table-container {
  padding-right: 2vw;
  .table {
    width: 100%;
    margin-bottom: 25px;

    .table-header {
      font-size: 14px;
      text-align: left;
      font-weight: 600;
      padding: 22px 25px;
      color: rgba(226, 232, 255, 0.7490196078);
      font-family: $secondary-font;
      display: flex;
      background-color: #212121;
      background-color: rgb(204 165 123 / 16%);

      div {
        width: 20%;

        &:first-of-type {
          width: 40%;
        }
      }

      i {
        margin: auto 0;
        margin-left: 8px;
        font-size: 16px;
      }

      p {
        margin: auto 0;
      }
    }

    .table-header {
      border-bottom: 1px solid #a8a8a830;
    }

    .row {
      &:last-of-type {
        border: none;

        &::after {
          display: none;
        }
      }

      .element {
        width: 20%;
        margin: auto;
        text-transform: capitalize;
        &:first-of-type {
          width: 40%;
        }

        .options {
          margin-right: 0;
          opacity: 0.75;
          transition: 0.25s ease all;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }

        .title {
          font-weight: 600;
        }

        .preview {
          background: rgba(78, 84, 95, 0.3);
          border-radius: 5px;
          display: flex;
          width: 50px;
          height: 50px;
          margin-right: 15px;
        }

        .name {
          margin: auto 0;
          overflow: hidden;
        }

        i {
          font-size: 18px;
          margin: auto;
        }

        h3 {
          margin: 0;
          line-height: 1;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 700;
        }

        p {
          margin: 0;
          font-weight: 300;
          opacity: 0.7;
          font-size: 11px;
          text-transform: lowercase;
        }
      }
    }

    .application-page {
      padding-top: 1rem;
    }
  }
}

.files-list {
  .table {
    .row {
      .element {
        &:first-of-type {
          width: 25%;
          min-width: 25%;
        }
      }
    }
  }
}

.file-container {
  @media only screen and (min-width: 1200px) {
    width: 24%;
  }

  //width: 300px;
  width: 31%;
  max-width: 300px;
  min-width: 200px;
  height: 200px;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &:hover {
    border-color: #ffffff94;
  }

  &:nth-of-type(even) {
    .file-details {
      transform: translate(-1px, 1px);
      width: 101%;
    }
  }

  .file-preview {
    display: flex;
    width: 100%;
    height: 100%;

    .document {
      width: 80%;
      height: 85%;
      background-position: top;
      background-size: cover;
      margin: auto;
      margin-bottom: 0;
      border-radius: 5px 5px 0 0;
      box-shadow: rgb(255 255 255 / 65%) 0px 2px 4px 0px,
        rgb(192 210 218 / 48%) 0px 2px 16px 0px;
      background-color: #fff;
    }

    .pdf {
      margin: auto;
      width: 60px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      filter: brightness(0) invert(1)
        drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.65))
        drop-shadow(0px 2px 16px rgba(192, 210, 218, 0.48));
      background-image: url(../icons/pdf-icon.png);
      opacity: 0.5;
      box-shadow: unset;
    }
  }

  .file-details {
    background: #4e545f94;
    display: flex;
    padding: 10px;
    width: 100%;
    height: fit-content;
    margin-bottom: 0;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(1px);
    overflow: hidden;
    border-radius: 0 0 4px 4px;

    position: absolute;
    width: 100%;
    box-sizing: border-box;
    height: 50px;
    bottom: 0;
    backdrop-filter: blur(10px);
    box-shadow: rgb(14 30 37 / 92%) 0px 2px 4px 0px,
      rgb(14 30 37 / 92%) 0px 2px 16px 0px;
    overflow: unset;
    .file-titles {
      width: 83%;
      padding-left: 4%;

      p {
        color: #ffffff75;
      }
    }

    .icon {
      font-size: 23px;
      color: #ffffffb3;
    }

    .dropdown-menu {
      bottom: unset;
      top: 0;
      transform: translateY(-58%);
    }
  }
}

.padding-top {
  padding-top: 5vh;
}

.pagination-container {
  display: flex;
  margin: auto;

  &.padding {
    margin-bottom: 5vh;
    padding-right: 4rem;
  }

  ul {
    display: flex;
    list-style: none;
    margin: auto;
    max-width: 100%;
    margin-right: 0;
    margin-top: 25px;
    transition: 0.25s ease all;
    margin: auto;

    li {
      width: 30px;
      height: 30px;
      border-radius: 3.626px;
      box-sizing: border-box;
      overflow: hidden;
      border: 1px solid rgba(109, 105, 105, 0.4);
      background: rgba(78, 84, 95, 0.3);
      cursor: pointer;
      margin-right: 7.25px;
      font-size: 13px;
      font-weight: 700;
      color: rgba(255, 255, 255, 0.723);

      &.selected {
        color: rgba(204, 165, 123, 0.8);
        box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
          inset -1.2000000477px 0 rgb(255 255 255 / 4%),
          inset 0 -1.2000000477px rgb(255 255 255 / 10%),
          inset 0 -0.6000000238px rgb(255 255 255 / 10%),
          rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
        border-color: rgba(204, 165, 123, 0.5490196078);
        background: rgba(31, 33, 37, 0.4588235294);
      }

      &.previous,
      &.next {
        &.disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.upload {
  input {
    position: 'absolute';
    width: '100%';
    height: '100%';
    z-index: 100;
  }
  input::file-selector-button {
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
.invoices-container {
  .dropdown-menu {
    box-shadow: rgba(0, 0, 0, 0.9) 0px 19px 38px, rgba(0, 0, 0, 0.52) 0px 15px 12px;
    z-index: 1000;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    background-color: #343437;
    z-index: 100;
    cursor: pointer;
    border-radius: 7px;
    width: -moz-fit-content;
    width: fit-content;
    color: white;
    border: 1px solid #ffffff47;
    bottom: 74%;
    transform: translateY(100%);
    font-size: 12px;
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;
    max-width: 200px;
    min-width: 100px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      padding: 4px 8px;
      cursor: pointer;
      width: 100%;
      display: flex;
      padding: 13px 24px;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid #ffffff29;
      transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
  
      &:hover {
        background-color: #ffffff29;

      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .dropdown-item, li {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
      max-width: unset !important;
      justify-content: flex-start;
      &:hover {
        background-color: #ffffff29;
      }

      i {
        font-size: 11px !important;
        margin: 0  !important;
      }
    }
  }
}

.invoice-licences-details {
  margin: 0px;
  font-size: 12px;
  opacity: .8;
  font-style: italic;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &.empty {
    flex-direction: row;
    gap: 5px;

    i {
      font-size: 14px;
    }
  }
}
