.analytics-page {
  padding-top: 2vh;
  padding-bottom: 2vh;
  .stats-cards-container {
    flex-wrap: wrap;
    gap: 15px;
    .stats-card {
      width: 45%;
      color: black;
      margin: 0;
      .title {
        color: #e2e8ff;
      }
    }
  }
}
