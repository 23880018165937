$primary-font: 'Inter', sans-serif;
$secondary-font: 'Poppins', sans-serif;
$menu-width: 325px;
$orange: #ea961a;

body {
  box-sizing: border-box;
  overflow: hidden;

  input[type="number"] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    -moz-appearance: textfield; // For Firefox
  }
  
}

.dashboard-container {
  background-color: #101011;
  color: white;
  min-height: 100vh;
  width: 100vw;
  display: flex;

  // overflow: hidden;
  a {
    text-decoration: none;
    color: unset;
    font-weight: 400;
  }

  font-family: $primary-font;

  .icon {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.analytics {
      background-image: url('../icons/analytics.png');
    }

    &.applications {
      background-image: url('../icons/analytics.png');
    }

    &.dashboard {
      background-image: url('../icons/dashboard.png');
    }

    &.settings {
      background-image: url('../icons/settings.png');
    }

    &.question-mark {
      background-image: url('../icons/question-mark.png');
    }

    &.connect-providers {
      background-image: url('../icons/connect-providers.png');
    }

    &.team-on-boarding {
      background-image: url('../icons/team-on-boarding.png');
    }

    &.invoices {
      background-image: url('../icons/invoices.png');
    }

    &.options {
      background-image: url('../icons/options.png');
    }

    &.file {
      background-image: url('../icons/file.png');
    }
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  max-width: calc(100vw - $menu-width);
}

.side-menu {
  background-color: #1b1b1b;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1.5rem;
  color: #8a8a8a;
  min-height: 100vh;
  text-transform: capitalize;
  // padding-right: 2rem;
  max-height: 100vh;
  width: $menu-width;
  min-width: $menu-width;
  box-sizing: border-box;
  background: radial-gradient(121.65% 100% at 32.68% 0,
      rgba(59, 57, 57, 0.93) 0,
      rgba(33, 33, 33, 0.68) 32.49%,
      rgba(60, 65, 85, 0) 51.34%),
    radial-gradient(91.41% 43.04% at 50% 0,
      rgba(33, 33, 33, 0.5568627451) 20.67%,
      rgba(26, 28, 36, 0) 100%),
    radial-gradient(69.96% 25.89% at 50% 100%,
      rgb(33, 33, 33) 22.77%,
      rgb(19, 19, 21) 100%);

  @media only screen and (max-width: 400px) {
    display: none;
  }

  .logo {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 1.2rem 0;

    .logo-image {
      background-image: url(../logo_reknah_temp.png);
      width: 43px;
      height: 35px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .logo-name {
      font-size: 1rem;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  .user {
    display: flex;
    gap: 0.8rem;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    position: relative;
    cursor: pointer;
    transition: 0.25s ease all;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      // background-color: #343434;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .user-name {
      color: white;
      font-weight: 600;
    }

    .user-position {
      font-weight: 400;
      font-size: smaller;
      text-transform: lowercase;
    }

    i {
      margin: auto;
      margin-right: 0;
      margin-top: 5px;
      opacity: 0.5;
      transition: 0.25s ease all;

      &:hover {
        opacity: 1;
      }
    }

    .user-cursor {
      position: absolute;
      left: 0;
      background-color: #ffc17f;
      width: 20px;
      height: 160%;
      top: 50%;
      transform: translate(-190%, -50%);
      border-radius: 15px;
    }
  }

  // .user::before {
  //   content: "";
  //   width: 50px;
  //   height: 20px;
  //   position: absolute;
  //   left: -10px;
  //   border-radius: 5px;
  //   background-color: #ffc17f;
  // }

  .elements-menu-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .menu-element {
      display: flex;
      align-items: center;
      padding: 0.8rem 1.5rem;
      gap: 1rem; //here
      border-radius: 15px; //here
      font-weight: 600;
      transition: 0.25s ease all;
      cursor: pointer;

      i {
        font-size: 20px;
      }

      .notification-dot {
        border-radius: 40px;
        border: 4px solid #cca57b;
        align-self: flex-start;
        display: none;
      }

      &.active,
      &:hover {
        background-color: #cca57b;
        font-weight: 600;
        color: white;

        a {
          font-weight: 600;
        }
      }

      &:hover {
        background-color: #4c4b49;
      }

      &.active {
        &:hover {
          background-color: #bc8850;
        }
      }
    }

    .section {
      display: flex;
      flex-direction: column;
      // gap: 0.5rem;
      margin-top: 2rem;

      &.menu-footer {
        margin-top: auto;
      }

      .section-title {
        font-size: 12px;
        text-transform: uppercase;
        padding: 1rem 0;
        font-weight: 700;
        margin: 0 1.5rem;
        border-top: 1px solid #a4a3a333;
      }

      &:first-of-type {
        .section-title {
          border: unset;
        }
      }
    }
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  background-color: #101011;
  padding: 1.5rem 2rem;
  padding-bottom: 1rem;
  height: 80px;
  box-sizing: border-box;

  // new
  position: fixed;
  top: 0;
  width: calc(100% - 325px);
  left: 325px;
  z-index: 10;
  backdrop-filter: blur(10px);
  background-color: rgb(16 16 17 / 58%);

  .menu-toggle {
    margin: auto;
    margin-left: 0px;
    font-size: 21px;
    color: #878789;
    display: none;
    transition: 0.25s ease all;

    &:hover {
      color: white;
    }

    @media only screen and (max-width: 490px) {
      display: flex;
    }
  }

  .notifications {
    display: flex;
    padding: 10px;
    position: relative;

    .bell {
      width: 20px;
      height: 20px;
      background-image: url('../icons/bell.png');
      opacity: 0.5;
      transition: 0.25s ease all;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    span {
      border-radius: 40px;
      border: 5px solid #cca57b;
      top: 6px;
      right: 0;
      position: absolute;
    }
  }

  .company {
    display: flex;
    gap: 0.8rem;
    padding-left: 2rem;
    //border-left: 2px solid rgba(164, 163, 163, 0.3);
    margin-left: 1rem;
    position: relative;

    &::after {
      content: '';
      height: 85%;
      width: 1px;
      position: absolute;
      left: 0;
      top: 7.5%;
      background-color: rgba(164, 163, 163, 0.3);
    }

    .company-image {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      border: 2px solid white;
      background-size: contain;
      background-position: center center;
      background-color: white;
      background-repeat: no-repeat;
      background-image: url('../images/empty-company.webp');
      @media only screen and (max-width: 490px) {
        width: 30px;
        height: 30px;
      }
    }

    i {
      width: 20px;
      height: 20px;
      margin: auto;
      cursor: pointer;
      transition: 0.25s ease all;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    .company-info {
      display: flex;
      flex-direction: column;
      gap: 2px;
      margin: auto;

      .company-name {
        color: white;
        font-weight: 600;
      }

      .company-account {
        color: #8a8a8a;
        font-weight: 400;
        font-size: 13px;
      }
    }
  }
}

.content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: calc(100vh - 80px);
  scroll-behavior: smooth;
  padding-left: 4vw;
  padding-top: 80px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .sphere {
    position: absolute;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.8) inset,
      0 0 10px rgba(255, 255, 255, 0.5);
    z-index: 2;
    background: radial-gradient(ellipse at 66% 18%, white -3%, #cca57b 33%);

    border-radius: 50%;

    &.one {
      bottom: 10%;
      right: -5%;
      width: 200px;
      height: 200px;
    }

    &.two {
      bottom: 7%;
      right: 45%;
      width: 100px;
      height: 100px;
    }

    &.three {
      top: 15%;
      right: -5%;
      width: 150px;
      height: 150px;
    }

    &.four {
      top: 20%;
      right: 40%;
      width: 100px;
      height: 100px;
    }

    &.five {
      bottom: -10%;
      right: 20%;
      width: 200px;
      height: 200px;
    }
  }
}

.titles-container {
  font-family: $primary-font;
  color: #8a8a8a;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  h2 {
    font-size: 28px;
    font-weight: 500;
    margin: 0;

    span {
      background-image: linear-gradient(rgb(255, 255, 255) 30%,
          rgba(255, 255, 255, 0.1) 100%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #ffff;

    background-image: linear-gradient(rgb(255, 255, 255) 10%,
        rgba(255, 255, 255, 0.8) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    transition: 0.25s ease all;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }

  span {
    font-family: $secondary-font;
    color: white;
    text-transform: capitalize;
    font-weight: 600;
  }
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    'widgets widgets menu'
    'widgets widgets menu';
  height: 100%;
  padding-top: 3rem;
  padding-left: 0;
  align-content: center;
  align-items: stretch;

  @media only screen and (min-width: 1200px) {
    padding-right: 4rem;
  }

  .card,
  .empty-card,
  .big-card {
    position: relative;
    background-color: #212121;
    border: 1px solid #8a8a8a70;
    // min-width: 200px;
    // min-height: 80%;
    height: 80%;
    width: 80%;

    border-radius: 15px;
    color: #bababa;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    gap: unset;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.56);
    box-sizing: border-box;
    box-shadow: rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    transition: 0.25s ease all;

    &:hover {
      box-shadow: rgb(0 0 0 / 90%) 0px 4px 16px, rgb(2 2 2 / 90%) 0px 8px 24px,
        rgba(0, 0, 0, 0.8) 0px 16px 56px;
    }

    .card-header {
      display: flex;
      align-items: center;
      gap: 10px;

      .icon {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        min-width: 25px;
      }

      i {
        font-size: 25px;
        margin-right: 10px;

        &.options {
          margin-left: auto;
          font-size: 17px;
          align-self: flex-start;
          opacity: 0.7;
          margin-right: 0;
        }

        &.background {
          background-color: #343437;
          padding: 0.4rem;
          border-radius: 7px;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        font-family: $secondary-font;
      }

      .title {
        font-weight: 500;
        font-size: 15px;
      }

      .subtitle {
        font-weight: 400;
        font-size: 10px;
        opacity: 0.7;
        text-transform: capitalize;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-family: $secondary-font;
      margin-top: 2rem;

      .label {
        display: flex;
        align-items: center;
        font-size: 13px;

        justify-content: space-between;
        font-weight: 400;
        font-family: $primary-font;

        .rate {
          opacity: 0.5;
          color: red;
          display: flex;
          gap: 5px;

          &.green {
            color: #50f632;
          }
        }

        .average {
          text-align: right;
          color: #bababa;
          opacity: 0.7;
        }
      }
    }

    .card-footer {
      font-family: $primary-font;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-top: 1px solid rgba(164, 163, 163, 0.2);
      gap: 5px;
      padding-top: 0.8rem;
      font-size: 12px;
      font-weight: 500;
      color: #8a8a8a;
      opacity: 0.6;
      margin-top: 0.8rem;

      i {
        font-size: 14px;
      }
    }
  }

  .dropdown-menu {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    background-color: #343437;
    z-index: 5;
    cursor: pointer;
    border-radius: 7px;
    width: fit-content;
    top: 23%;
    right: -11%;

    .dropdown-item {
      color: white;
      font-size: 10px;
      font-weight: 300;
      box-sizing: border-box;
      padding: 10px;
      width: fit-content;

      justify-content: center;
      display: flex;
      align-items: center;
      gap: 10px;
      opacity: 0.8;

      i {
        font-size: 14px !important;
        margin: 0;
      }
    }
  }

  .analytics {
    padding-bottom: 0;
    height: unset;
    width: 90%;
    gap: 0;
    padding: 1rem 1.5rem;
    
    .title {
      font-weight: 500;
    }

    .budget {
      color: white;
      margin-top: 0.5rem;

      span {
        color: #bababa;
        font-size: 12px;
        font-weight: 300;
      }
    }

    .button {
      padding: 5px 17px;
      position: absolute;
      bottom: 0;
    }

    .card-header {
      .button {
        display: flex;
        align-items: center;
        font-size: 10px;
        margin-left: auto;
        background-color: #343437;
        padding: 0.5rem;
        border-radius: 7px;
        gap: 10%;

        position: absolute;
        bottom: 0;
        right: 25px;

        i {
          font-size: 14px;
        }

        text-transform: capitalize;

        .dropdown-menu {
          top: 100%;

          width: 100%;
        }
      }
    }
  }

  div[data-react-beautiful-dnd-draggable] {
    &:has(.analytics) {
      max-width: initial;
    }
  }

  @container card-container (min-inline-size: 400px) {
    .analytics {
      max-height: 90% !important;
      height: 90% !important;
    }
  }

  .empty-card {
    align-self: flex-end;
    // justify-self: end;
    background-color: #2121218e;
    background: radial-gradient(121.65% 100% at 32.68% 0,
        rgb(59 57 57 / 93%) 0,
        rgba(33, 33, 33, 0.68) 32.49%,
        rgba(60, 65, 85, 0) 51.34%),
      radial-gradient(91.41% 43.04% at 50% 0,
        rgba(33, 33, 33, 0.5568627451) 20.67%,
        rgba(26, 28, 36, 0) 100%),
      radial-gradient(69.96% 25.89% at 50% 100%,
        rgba(33, 33, 33, 0.5568627451) 22.77%,
        rgba(19, 21, 27, 0) 100%);
    height: 219px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    transition: 1.25s ease all;
    backdrop-filter: blur(10px);
    box-shadow: inset 1.2000000476837158px 0 #ffffff0a,
      inset -1.2000000476837158px 0 #ffffff0a,
      inset 0 -1.2000000476837158px #ffffff1a,
      inset 0 -0.6000000238418579px #ffffff1a, rgb(0 0 0 / 90%) 0px 19px 38px,
      rgb(0 0 0 / 52%) 0px 15px 12px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 15px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-bottom: none;
      border-radius: 13px 13px 0 0;
      width: 100%;
      transition: 1.25s ease all;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      border: 1px solid rgba(255, 255, 255, 0.8);
      inset: 15px 0 0 0;
      border-top: none;
      border-image: linear-gradient(180deg,
          rgba(255, 255, 255, 0.6) 0,
          transparent 40%) 1;
      width: 100%;
      top: 16px;
      transition: 1.25s ease all;
      pointer-events: none;
    }

    .add-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #8a8a8a;
      margin: 0 auto;
      opacity: 1;
      transform: translateY(10%);

      .plus-sign {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        color: white;
        background-color: #3d3d3e;
        border-radius: 100px;
        box-shadow: rgba(14, 16, 16, 0.82) 0px 2px 4px 0px,
          rgba(24, 24, 24, 0.92) 0px 2px 16px 0px;
        box-shadow: rgb(0 0 0) 0px 2px 4px 0px, rgb(24 24 24) 0px 2px 16px 0px;
        transition: 0.25s ease all;
        background: linear-gradient(180deg, #464343 0%, rgb(24 24 24) 100%);
        box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px 0px,
          rgb(24 24 24 / 50%) 0px 2px 16px 0px;

        i {
          font-size: 25px;
          font-weight: 500;
          text-shadow: 2px 2px 5px black;
          transition: 0.75s ease all;
        }
      }

      p {
        font-size: 13px;
      }
    }

    &:hover {
      box-shadow: rgb(14 30 37 / 82%) 0px 2px 4px 0px,
        rgb(14 30 37 / 92%) 0px 2px 16px 0px;
      box-shadow: inset 1.2000000476837158px 0 #ffffff0a,
        inset -1.2000000476837158px 0 #ffffff0a,
        inset 0 -1.2000000476837158px #ffffff1a,
        inset 0 -0.6000000238418579px #ffffff1a,
        rgb(14 30 37 / 82%) 0px 2px 4px 0px,
        rgb(14 30 37 / 92%) 0px 2px 16px 0px;

      .plus-sign {
        transform: scale(1.1);

        i {
          transform: scale(1.3);
        }
      }

      &::after {
        border-image: linear-gradient(180deg,
            rgba(255, 255, 255, 0.8) 0,
            transparent 80%) 1;
      }

      &::before {
        border-color: rgba(255, 255, 255, 0.8);
      }
    }
  }

  .big-card {
    height: unset;
    width: unset;
    grid-area: menu;
    width: 24vw;
    padding: 1rem 2.5rem;
    border-radius: 30px;
    border: 1px solid #807b7b9f;
    height: 600px;
    background: radial-gradient(121.65% 100% at 32.68% 0,
        rgba(59, 57, 57, 1) 0,
        rgba(33, 33, 33, 0.68) 32.49%,
        rgba(60, 65, 85, 0) 51.34%),
      radial-gradient(91.41% 43.04% at 50% 0,
        rgba(33, 33, 33, 0.5568627451) 20.67%,
        rgba(26, 28, 36, 0) 100%),
      radial-gradient(69.96% 25.89% at 50% 100%,
        rgba(33, 33, 33, 0.8) 70%,
        rgba(19, 21, 27, 0) 100%);
    border-color: rgba(255, 255, 255, 0.1);
    justify-content: unset;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 30px;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-bottom: none;
      border-radius: 30px 30px 0 0;
      width: 100%;
      transition: 1.25s ease all;
      pointer-events: none;
    }

    &::after {
      content: '';
      position: absolute;
      border: 1px solid rgba(255, 255, 255, 0.8);
      inset: 30px 0 0 0;
      border-top: none;
      border-image: linear-gradient(180deg,
          rgba(255, 255, 255, 0.6) 0,
          transparent 20%) 1;
      width: 100%;
      top: 30px;
      transition: 1.25s ease all;
      pointer-events: none;
    }

    .card-header {
      margin-top: 1rem;

      .title {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }

  .widgets {
    grid-area: widgets;
    display: flex;
    flex-direction: column;

    >* {
      //flex: 1;

      >* {
        //flex: 1;
        flex-wrap: wrap;
        width: 50%;
        //max-height: 60%;
        height: 100%;
        height: fit-content !important;
        margin-bottom: 10vh;

        @media only screen and (max-width: 760px) {
          margin-bottom: 5vh;
        }
      }
    }
  }

  .widgets-row {
    flex: 1;
    display: flex;
  }

  .container {
    position: relative;
    z-index: 5;
    flex: 1;
    height: 100%;
    width: 100%;
    // container-type: inline-size;
    // container-name: card-container;
  }

  .card {
    max-height: 219.2px;
    height: 219.2px;
    background: radial-gradient(121.65% 100% at 32.68% 0,
        rgba(59, 57, 57, 0.93) 0,
        rgba(33, 33, 33, 0.68) 32.49%,
        rgba(60, 65, 85, 0) 51.34%),
      radial-gradient(91.41% 43.04% at 50% 0,
        rgba(33, 33, 33, 0.5568627451) 20.67%,
        rgba(26, 28, 36, 0) 100%),
      radial-gradient(69.96% 25.89% at 50% 100%,
        rgba(33, 33, 33, 1) 22.77%,
        rgba(19, 21, 27, 1) 100%);
    background: radial-gradient(121.65% 100% at 32.68% 0,
        rgba(59, 57, 57, 0.93) 0,
        rgba(33, 33, 33, 0.68) 32.49%,
        rgba(60, 65, 85, 0) 51.34%),
      radial-gradient(91.41% 43.04% at 50% 0,
        rgba(33, 33, 33, 0.5568627451) 20.67%,
        rgba(26, 28, 36, 0) 100%),
      radial-gradient(69.96% 25.89% at 50% 100%,
        rgb(33, 33, 33) 22.77%,
        rgb(19 19 21) 100%);

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 15px;
      background-color: #343434;
      border: 1px solid black;
      box-shadow: rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
      background: radial-gradient(121.65% 100% at 32.68% 0,
          rgba(59, 57, 57, 0.93) 0,
          rgba(33, 33, 33, 0.68) 32.49%,
          rgba(60, 65, 85, 0) 51.34%),
        radial-gradient(91.41% 43.04% at 50% 0,
          rgba(33, 33, 33, 0.5568627451) 20.67%,
          rgba(26, 28, 36, 0) 100%),
        radial-gradient(69.96% 25.89% at 50% 100%,
          rgba(33, 33, 33, 1) 22.77%,
          rgba(19, 21, 27, 1) 100%);
    }

    &::before {
      top: -4px;
      left: 10px;
      transform: rotate(4deg);
      z-index: -1;
      box-shadow: rgba(0, 0, 0, 0.8) 0px 19px 38px,
        rgba(0, 0, 0, 0.9) 0px 15px 12px;
      transition: 0.25s ease all;
    }

    &::after {
      top: -6px;
      left: 21px;
      transform: rotate(8deg);
      opacity: 0.36;
      z-index: -2;
      transition: 0.35s ease all;
    }

    &:hover {
      &::after {
        box-shadow: rgba(30, 28, 28, 0.96) x 5px 12px 10px;
        transform: rotate(18deg);
        top: 7px;
        left: 35px;
      }

      &::before {
        box-shadow: rgba(0, 0, 0, 0.96) 0px 12px 7px 4px;
        transform: rotate(10deg);
        left: 20px;
        top: -13px;
      }
    }

    &.right,
    &.left {
      &::after {
        box-shadow: rgba(30, 28, 28, 0.96) x 5px 12px 10px;
        transform: rotate(18deg);
        top: 28px;
        left: -35px;
      }

      &::before {
        box-shadow: rgba(0, 0, 0, 0.96) 0px 12px 7px 4px;
        transform: rotate(10deg);
        left: -20px;
        top: 18px;
      }
    }
  }

  &.most-used-software {
    display: flex;
    padding-bottom: 3rem;
    width: 90%;
    max-width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    height: fit-content;

    transform: translateX(-4vw);
    padding-left: 4vw;
    width: 100%;

    .most-used-software-scroll {
      display: flex;
      width: fit-content;

      .software-card {
        margin-right: 25px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.team-overview {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
    padding-bottom: 5rem;
    padding-top: 5rem;
    height: 500px;

    .container {
      height: fit-content;
      width: fit-content;
      margin: auto;

      .explanation {
        display: none;
        margin: auto;
        width: fit-content;
        font-weight: 300;
        font-size: 10px;
        margin-top: 10px;
        opacity: 0.5;

        text-transform: capitalize;
        font-weight: 500;
        transition: 0.5s ease all;
        position: absolute;
        bottom: -16%;
        left: 50%;
        width: 100%;
        text-align: center;
        transform: translateX(-50%);
      }

      .orbits-container {
        position: absolute;
        width: fit-content;
        height: fit-content;
        top: 0;
        left: 0;
        z-index: -1;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        box-sizing: border-box;

        .orbits {
          width: 300px;
          height: 300px;
          border-radius: 50%;
          border: 2px solid #433f3f4d;
          margin: auto;
          // transform: translate(0%,-25%);
          background: radial-gradient(121.65% 100% at 32.68% 0,
              rgba(59, 57, 57, 0.93) 0,
              rgba(33, 33, 33, 0.68) 32.49%,
              rgba(60, 65, 85, 0) 51.34%),
            radial-gradient(91.41% 43.04% at 50% 0,
              rgba(33, 33, 33, 0.5568627451) 20.67%,
              rgba(26, 28, 36, 0) 100%),
            radial-gradient(69.96% 25.89% at 50% 100%,
              rgba(33, 33, 33, 0.5568627451) 22.77%,
              rgba(19, 21, 27, 0) 100%);
          box-sizing: border-box;
          position: relative;
          box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
            inset -1.2000000477px 0 rgb(255 255 255 / 4%),
            inset 0 1.2000000477px rgb(255 255 255 / 10%),
            inset 0 0.6000000238px rgb(255 255 255 / 10%),
            rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
          transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
          cursor: pointer;
          animation: rotation 400s linear infinite;

          &.clicked {
            transform: scale(1.025) rotate(45deg);
            animation: unset;

            .element {
              animation: unset;

              &.member {
                div {
                  transform: rotate(-45deg);
                }
              }
            }

            &::before {
              transform: scale(1.04);
              filter: blur(0);
            }

            &::after {
              transform: scale(1.06);
              filter: blur(1px);
            }
          }

          &::before,
          &::after {
            content: '';
            position: absolute;
            width: 140%;
            height: 140%;
            border: 2px solid #433f3f4d;
            border-radius: 50%;
            left: -20%;
            top: -20%;
            box-sizing: border-box;
            box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
              inset -1.2000000477px 0 rgb(255 255 255 / 4%),
              inset 0 1.2000000477px rgb(255 255 255 / 10%),
              inset 0 0.6000000238px rgb(255 255 255 / 10%),
              rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
            transition: 0.5s cubic-bezier(0.43, 0.195, 0.02, 1) all;
            cursor: pointer;
            opacity: 0.7;
            filter: blur(2px);
          }

          &::after {
            width: 185%;
            height: 185%;
            box-sizing: border-box;
            top: -42.5%;
            left: -42.5%;
            transition: 0.75s cubic-bezier(0.43, 0.195, 0.02, 1) all;
            opacity: 0.4;
            filter: blur(5px);
          }

          .element {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #cca57b;
            box-shadow: 0 0 20px 10px rgb(204 165 123 / 30%);
            transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
            position: absolute;
            z-index: 1;
            transform: translateX(50%);
            display: flex;
            justify-content: center;
            align-items: center;
            animation: rotation-reverse 400s forwards linear infinite;

            &.member {
              width: 50px;
              height: 50px;
              box-shadow: 0 0 20px 10px rgb(204 165 123 / 50%);

              .user-image {
                transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
                width: 90%;
                height: 90%;
                background-image: url(https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg);
                border-radius: 50%;
                background-size: cover;
                margin: auto;
                border: none;
              }
            }
          }
        }
      }

      &.team-container {
        width: 90%;
        height: 100%;
        margin: auto;
        margin-right: 0;

        .title-label {
          font-weight: 300;
          font-size: 12px;
          opacity: 0.5;
          font-family: $secondary-font;
        }

        .title {
          font-size: 25px;
          font-weight: 700;
          font-family: $secondary-font;
          text-transform: capitalize;
          line-height: 1.25;

          span {
            color: #ffc17f;
            opacity: 0.9;
            text-shadow: 0 0 20px rgb(204 165 123 / 70%);

            background-image: linear-gradient(#ffc17f 20%, #ff95108c 100%);
            //background-image: linear-gradient(#ffc17f 20%, #ff95105c 100%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
          }
        }

        .team-row {
          padding-top: 1rem;

          .table {
            width: 100%;
            min-height: 100px;
          }

          .manager {
            display: flex;
          }

          .manager-container {
            h3 {
              margin-bottom: 10px;
              font-size: 16px;
              font-weight: 500;
            }

            .manager {
              display: flex;

              .user-image {
                width: 40px;
                height: 40px;
                border: 0;
                box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 20%),
                  inset -1.2000000477px 0 rgb(255 255 255 / 20%),
                  inset 0 1.2000000477px rgb(255 255 255 / 20%),
                  inset 0 0.6000000238px rgb(255 255 255 / 20%),
                  rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
              }

              .name-titles {
                height: fit-content;
                margin: auto;
                margin-left: 15px;

                h4 {
                  margin: 0;
                  font-size: 13px;
                  font-weight: 300;
                  opacity: 0.8;
                  width: fit-content;
                  position: relative;

                  i {
                    top: 0;
                    right: -17px;
                    position: absolute;
                    font-size: 11px;
                  }
                }

                h5 {
                  margin: 0;
                  font-size: 16px;
                }
              }
            }
          }

          h3 {
            font-size: 16px;
            font-weight: 500;
            font-family: $primary-font;
            margin: 0;

            font-weight: 700;
            font-size: 40px;
            opacity: 0.3;
            font-size: 30px;
          }

          p {
            margin: 0;
            font-size: 10px;
            line-height: 2;
            opacity: 0.5;
          }

          .team-stats {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            padding-top: 3rem;

            &:nth-child(n) {
              animation-delay: 0.5s;
            }

            .stat {
              padding-top: 2rem;
              animation: appear 0.5s ease-in-out;

              h3 {
                font-size: 35px;
                background-image: linear-gradient(rgb(255, 255, 255) 50%,
                    rgba(255, 255, 255, 0.1) 100%);
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                font-weight: 600;
                margin: 0;
                opacity: 1;
                font-family: $secondary-font;
                line-height: 1.2;

                span {
                  background-image: linear-gradient(rgb(255, 255, 255) 50%,
                      rgba(255, 255, 255, 0.1) 100%);
                  -webkit-text-fill-color: transparent;
                  -webkit-background-clip: text;
                  opacity: 0.8;
                }

                .currency {
                  font-family: $primary-font;
                }

                .secondary {
                  font-size: 16px;
                  opacity: 0.5;
                  color: rgba(255, 255, 255, 0.4);
                  margin-left: 5px;
                }
              }

              p {
                font-size: 13px;
                margin: 0;
                line-height: 1.2;
                font-weight: 300;
                width: 85%;
                transition: 0.25s ease all;
                min-height: 35px;
                color: rgb(141 141 141);
                opacity: 1;

                &.show-more {
                  margin-top: 5px;
                  margin-right: 0;
                  text-transform: uppercase;
                  font-size: 10px;
                  font-weight: 600;
                  opacity: 0.8;
                  cursor: pointer;
                  min-height: unset;
                  width: fit-content;
                  color: rgb(255, 255, 255);
                  opacity: 0.5;

                  &:hover {
                    color: #cca57b;
                    opacity: 1;
                  }
                }

                i {
                  font-size: 13px;
                  margin: auto;
                  transform: translateY(1.5px);
                }

                span {
                  color: #cca57b;
                  color: rgba(255, 255, 255, 0.8);
                  font-weight: 500;
                }
              }

              &.go-back {
                cursor: pointer;
                opacity: 0.8;
                transition: 0.5s ease all;

                &:hover {
                  opacity: 1;
                }

                p {
                  color: rgb(255 255 255);
                  margin: 0;
                }
              }
            }
          }

          .next-team-buttons-container {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding-top: 40px;

            .button-container {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: .5s ease-in-out all;
              opacity: .6;

              &:hover {
                opacity: 1;
                color: #ffc17f;
              }

              &.reversed {
                flex-direction: row-reverse;
              }

              p {
                font-size: 10px;
                font-weight: 600;
                opacity: .9;
                text-transform: uppercase;

                &.dot {
                  font-size: 14px;
                  margin-left: 5px;
                  margin-right: 5px;
                  transform: translateY(-1px);
                }
              }
            }
          }

        }
      }
    }
  }
}

.dashboard-element {
  padding: 3rem 0;

  @media only screen and (min-width: 1200px) {
    padding-right: 4rem;
  }

  &.no-top {
    padding-top: 0;
  }
}

.table-cards {
  display: grid;
  gap: 8px;

  .row {
    background: radial-gradient(113.03929211915995% 100% at 50.000010106500284% -1.3322676295501878e-13%,
        #181818 0%,
        rgba(25, 25, 25, 1) 0.009999999747378752%,
        hsla(0, 0%, 3%, 0.81) 100%);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.07);
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 1.2000000477px rgb(255 255 255 / 10%),
      inset 0 0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    width: 500px;
    position: relative;
    box-sizing: border-box;
    padding: 20px 30px;
    cursor: pointer;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
    color: #e2e8ff;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &:hover {
      //transform: translate(15px, -5px) scale(1.02);
    }

    .element {
      font-family: $secondary-font;
      width: calc(1 / 5 * 100%);
      display: flex;
      font-weight: 400;
      font-size: 13px;

      &.main {
        font-weight: 600;
      }

      div {
        margin: auto 0;
      }

      i {
        margin: auto 5px;
      }

      .icon {
        width: 18px;
        height: 18px;
        display: block;
        margin-right: 8px;
      }
    }
  }
}

.table {
  display: grid;
  //gap: 8px;
  background: radial-gradient(113.03929211915995% 100% at 50.000010106500284% -1.3322676295501878e-13%,
      #181818 0%,
      rgba(25, 25, 25, 1) 0.009999999747378752%,
      hsla(0, 0%, 3%, 0.81) 100%);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
    inset -1.2000000477px 0 rgb(255 255 255 / 4%),
    inset 0 1.2000000477px rgb(255 255 255 / 10%),
    inset 0 0.6000000238px rgb(255 255 255 / 10%),
    rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
  box-sizing: border-box;
  overflow: hidden;
  background: radial-gradient(121.65% 100% at 32.68% 0,
      rgba(59, 57, 57, 0.93) 0,
      rgba(33, 33, 33, 0.68) 32.49%,
      rgba(60, 65, 85, 0) 51.34%),
    radial-gradient(91.41% 43.04% at 50% 0,
      rgba(33, 33, 33, 0.5568627451) 20.67%,
      rgba(26, 28, 36, 0) 100%),
    radial-gradient(69.96% 25.89% at 50% 100%,
      rgba(33, 33, 33, 0.5568627451) 22.77%,
      rgba(19, 21, 27, 0) 100%);

  .row {
    position: relative;
    box-sizing: border-box;
    padding: 22px 30px;
    cursor: pointer;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
    color: #e2e8ff;
    width: 100%;
    display: flex;
    justify-content: space-between;

    // border-bottom: 1px solid rgba(250,250,250,0.2);
    &.active {
      background: #cca57b;

      &:hover {
        transform: unset;
      }
    }

    &.add {
      padding: 15px;

      .element {
        width: fit-content;
        margin: auto;
      }

      .plus-sign {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        color: white;
        background-color: #3d3d3e;
        border-radius: 100px;
        transition: 0.25s cubic-bezier(0.43, 0.195, 0.02, 1) all;
        box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px 0px,
          rgb(24 24 24 / 50%) 0px 2px 16px 0px;
        transform-origin: center center;

        i {
          font-size: 20px;
          font-weight: 500;
          text-shadow: 2px 2px 5px black;
          transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
        }
      }

      &:hover {
        transform: unset;

        .plus-sign {
          //transform: rotateZ(45deg);

          i {
            transform: rotateZ(90deg);
          }
        }
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      opacity: 1;
      background-image: linear-gradient(to right,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0));
      border-radius: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &:last-of-type {
      border: none;

      &::after {
        display: none;
      }
    }

    &:nth-of-type(2n) {
      //background-color: #272424;
    }

    &:hover {
      // transform: translate(15px, -5px) scale(1.02);
      background-color: #272424;
    }

    .element {
      font-family: $secondary-font;
      width: calc(1 / 5 * 100%);
      display: flex;
      font-weight: 400;
      font-size: 13px;
      width: 30%;

      label {
        font-size: 9px;
        opacity: .7;
        margin: auto 5px;
        margin-bottom: 1px;
      }
      &.main {
        font-weight: 600;
        width: 60%;
        min-width: 20% !important;
      }

      &.shrink {
        width: 15%;
      }

      &.options {
        width: 5%;
      }

      div {
        margin: auto 0;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
      }

      i {
        margin: auto 5px;
      }

      .icon {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        display: block;
        margin-right: 8px;

        &.avatar {
          border-radius: 50%;
          background-image: url(https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg);
        }
      }

      .user-activity {
        color: #436850;
        text-shadow: 0 0 5px #436850;

        &.not-active {
          color: #d24545;
          text-shadow: 0 0 5px #d24545;
        }
      }
    }

    &.head {
      background-color: #212121;
      display: none; // temp

      .element {
        font-family: $primary-font;
        font-weight: 700;
      }

      &:hover {
        transform: unset;
      }
    }
  }
}

.progress-bar-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .progress-bar {
    width: 100%;
    background-color: #4a4140;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 0 20px 10px rgb(204 165 123 / 15%);
  }

  .progress-bar-fill {
    height: 0.4rem;
    background-color: #cca57b;
    border-radius: 15px;
    box-shadow: 0 0 20px 10px rgb(204 165 123 / 50%);
  }

  .text {
    display: flex;
    justify-content: space-between;
    font-size: x-small;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 12px;

    .values {
      color: #cca57b;
      opacity: 0.81;
      font-family: $primary-font;
    }

    span {
      opacity: 1;
      font-weight: 600;
    }
  }
}

.goals-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-transform: capitalize;

  .chevron {
    background-color: #343434;
    border-radius: 50%;
    padding: 0.3rem;
    border: 2px solid #ea961a;
    color: #ea961a;
    position: absolute;
    bottom: calc(50% + 15px);
    z-index: 2;

    &.right {
      right: -5%;
    }

    &.left {
      left: -5%;
    }
  }

  .goal-card,
  .goal-card-behind {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    padding: 1.75rem 1.5rem;
    color: white;
    background-color: #cca57b;
    border-radius: 20px;
    border: 1px solid #807b7b;
    box-shadow: 0 0 70px rgba(0, 0, 0, 0.56);
    // min-width: 200px;
    background-image: url('https://res.cloudinary.com/dg91u71x5/image/upload/v1700171627/nrl8chocir7yiltbbgyy.png');
    background-position: center;
    background-size: cover;
    border: 1px solid #807b7b;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 20px 10px rgb(204 165 123 / 10%);
    border: unset;
    background: linear-gradient(227deg, #f5ae60 0%, rgb(110 84 70) 100%);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 20px;
      background-image: url(https://c02.purpledshub.com/uploads/sites/41/2021/08/mountains-7ddde89.jpg);
      background-position: center;
      mix-blend-mode: soft-light;
      opacity: 0.3;
      background-image: url(https://images.pexels.com/photos/4175070/pexels-photo-4175070.jpeg?auto=compress&w=1800);
      background-image: url(https://images.pexels.com/photos/4871011/pexels-photo-4871011.jpeg?auto=compress&w=1800);
      mix-blend-mode: saturation;
    }

    .goal-title {
      text-transform: capitalize;
      font-family: $secondary-font;
      font-size: small;
      font-weight: 400;
    }

    .value {
      display: flex;
      align-items: center;
      font-size: xx-large;
      font-weight: 500;
      gap: 5px;

      span {
        font-size: large;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: x-small;
        font-weight: 400;
      }

      .icons {
        color: white;
        display: flex;
        font-size: 32px;

        .behind {
          opacity: 0.8;
          margin-left: -1rem;
        }
      }
    }
  }

  .goal-card-behind {
    width: 90%;
    height: 50%;
    border-radius: 20px;
    background-color: #eeccaf;
    opacity: 0.9;
    position: absolute;
    top: -5%;
    left: 5%;
    padding: 0;
    z-index: 0;
    border-radius: 15px;
    background-color: #b28059;
  }
}

.events-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  p {
    font-weight: 400;
    color: #bababa;
    font-size: 20px;
    margin: 12px 0;
    margin-bottom: 0 !important;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 20px;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    background: linear-gradient(to bottom, #101011 50%, rgba(255, 255, 255, 0));
    height: 40px;
    top: 32px;

    @media only screen and (max-width: 1280px) and (min-width: 900px) {
      top: 26px;
      height: 30px;
    }

  }

  &::after {
    bottom: 0;
    background: linear-gradient(to top, rgba(30, 30, 30, .9) 24%, rgba(255, 255, 255, 0));
    height: 35px;
  }

  .events-scroll {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 228px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .event-row {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
    font-size: smaller;

    &:first-of-type {
      padding-top: 10px;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }

    .event-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: white;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      flex-shrink: 0;
      background-image: url(../images/empty.webp);
    }

    .event-text {
      display: flex;
      flex-direction: column;
      gap: 1px;

      .title {
        font-weight: 600;
      }

      .subtitle {
        font-weight: 400;
        font-size: x-small;
        color: #bababa;
      }
    }

    .value {
      margin-left: auto;
      font-weight: 400;
      opacity: 0.8;

      i {
        color: #bababa;
      }
    }
  }
}

section {
  .section-content {
    padding-bottom: 3rem;
    padding-bottom: 30rem;
    grid-template-columns: repeat(1, 1fr);
  }

  .saas-elements-container {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    width: 70%;
    transform: translate(-5%, -20%);

    .row {
      display: flex;
      flex-wrap: wrap;
      height: fit-content;

      &.grid {
        -webkit-mask-image: linear-gradient(to top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 60%);

        &.bottom {
          -webkit-mask-image: linear-gradient(to bottom,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0) 60%);

          .empty-card {
            opacity: 0.25 !important;
          }
        }

        .empty-card {
          opacity: 0.8;
        }
      }

      .saas-element {
        &:first-of-type {
          opacity: 0.5;
          -webkit-mask-image: linear-gradient(to left,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0) 90%);
        }

        &:last-of-type {
          opacity: 0.5;
          -webkit-mask-image: linear-gradient(to right,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 0) 90%);
        }
      }
    }
  }

  .saas-element {
    width: unset !important;
    height: unset !important;

    width: 120px !important;
    height: 120px !important;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 30px;
    margin-bottom: 30px;

    &:first-child {
      // margin-left: 0;
    }

    &.empty-card {
      border-radius: 30px;
      transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
      align-self: flex-start;
      box-shadow: rgb(0 0 0 / 90%) 0px 4px 16px, rgb(2 2 2 / 90%) 0px 8px 24px,
        rgb(0 0 0 / 80%) 0px 16px 56px;

      &::before {
        height: 30px;
        border-radius: 30px 30px 0 0;
      }

      &::after {
        inset: 31px 0 0 0;
      }

      &.reknah {
        transform: scale(1.05);
        box-shadow: rgb(204 165 123 / 25%) 0px 0px 4px 0px,
          rgb(255 151 40 / 25%) 0px 0px 16px 0px;
        animation: reknah-card-idle-initial 3.5s ease-in-out infinite forwards;

        &:hover {
          animation: reknah-card-spark 3.5s ease-in-out infinite forwards;
        }

        .icon {
          background-size: 100%;
        }
      }

      &.grid {
        opacity: 0.25;

        &:hover {
          // transform: none;
          // box-shadow: initial;
        }

        .icon {
          display: none;
        }
      }
    }

    .icon {
      background-size: 60%;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      filter: drop-shadow(0 1px 4px rgba(255, 255, 255, 0));
      transition: 0.9s cubic-bezier(0.51, 0.22, 0, 1.73) all;
    }

    &:hover {
      transform: scale(1.05);

      .icon {
        transform: scale(1.08);
        filter: drop-shadow(0 1px 4px rgba(204, 165, 123, 0.25));
      }
    }
  }

  &.budget-analaysis {
    .dashboard-grid {
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(2, 1fr);
      height: fit-content;
      padding-bottom: 3rem;

      .container {
        height: fit-content;
        width: fit-content;
        margin: auto;
      }
    }
  }
}

.table-rankings-preview-container {
  position: absolute;
  right: 10%;
  height: fit-content;
  width: fit-content;
  transform: translate(15%, 22%);
  transition: 0.9s cubic-bezier(0.51, 0.22, 0, 1.73) all;
  cursor: pointer;

  &:hover {
    .table-rankings-preview {
      transform: translateY(-5%);

      &.before {
        transform: translateY(-8%);
        width: 105%;
        left: -2.5%;
      }

      &.third {
        transform: translateY(-11%);
        width: 110%;
        left: -5%;
      }
    }
  }
}

.table-rankings-preview {
  padding: 16px 20px;
  border-radius: 10px;
  background: radial-gradient(120.05% 100% at 50% 0%,
      rgba(226, 232, 255, 0) 33.78%,
      rgba(226, 232, 255, 0.04) 100%),
    rgba(226, 232, 255, 0.01);
  box-shadow: 0 -28px 84px -24px #e2e8ff1f inset;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 350px;
  min-height: 200px;
  border: 1px solid rgba(226, 232, 255, 0.1);
  color: #e2e8ff;
  z-index: 3;
  transition: 0.9s cubic-bezier(0.51, 0.22, 0, 1.73) all;

  &.before {
    width: 110%;
    height: 85%;
    position: absolute;
    left: -5%;
    top: 7.5%;
    z-index: 0;
    box-sizing: border-box;
    filter: blur(0.5px);

    &.third {
      z-index: -1;
      width: 120%;
      height: 70%;
      left: -10%;
      top: 15%;
      min-height: unset;
      min-width: unset;
    }
  }

  .titles-container {
    display: flex;

    .title {
      font-weight: 500;
      font-size: 15px;
    }

    .subtitle {
      font-size: 10px;
      font-weight: 400;
    }
  }

  .table-content {
    .row {
      padding: 12px 0;
      color: #e2e8ffbf;
      display: flex;
      gap: 8px;
      border-bottom: 1px solid rgba(226, 232, 255, 0.08);
      font-size: 11px;

      &:last-child {
        border-bottom: 0;
      }
    }

    span {
      opacity: 0.55;
      font-weight: 200;
      margin: auto;

      &.label {
        flex: 1;
        opacity: 1;
        font-weight: 500;
        font-size: 13px;
        display: flex;

        div {
          margin-right: 7px;
          width: 16px;
          height: 16px;
        }
      }

      &.owner {
        min-width: 36px;
        text-align: center;
      }
    }
  }
}

.flex {
  display: flex;
}

.software-card {
  //temp
  width: 150px;
  height: 300px;

  min-height: 300px;
  font-family: $secondary-font;
  background: radial-gradient(113.03929211915995% 100% at 50.000010106500284% -1.3322676295501878e-13%,
      #181818 0%,
      rgba(25, 25, 25, 1) 0.009999999747378752%,
      hsla(0, 0%, 3%, 0.81) 100%);
  border-radius: 15px;
  border-radius: 15px 15px 5px 15px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
    inset -1.2000000477px 0 rgb(255 255 255 / 4%),
    inset 0 -1.2000000477px rgb(255 255 255 / 10%),
    inset 0 -0.6000000238px rgb(255 255 255 / 10%),
    rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
  box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
    inset -1.2000000477px 0 rgb(255 255 255 / 4%),
    inset 0 1.2000000477px rgb(255 255 255 / 10%),
    inset 0 0.6000000238px rgb(255 255 255 / 10%),
    rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
  width: 500px;
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  cursor: pointer;
  transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
  color: #e2e8ff;
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 25px;
  /*background: #e2e8ff;
    color: rgba(25,25,25,1);*/
  justify-content: space-between;
  user-select: none;
  &:hover {
    transform: scale(1.025);
  }

  &.selected {
    transition: 0.4s ease all !important;
    transform: scale(1.025);
    height: 392.5px;

    /* background: #cca57b;
        color: #fff;

        div {
            opacity: 1 !important;
        }

        .overlay {
            transition: .5s ease all !important;
            opacity: .15 !important;
        }

        .company-logo-image {
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.4));
        }

        span {
            opacity: 1 !important;

            &.decimal {
                opacity: .8 !important;
            }
        }

        .label {
            opacity: .8 !important;
        }*/
  }

  .overlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-blend-mode: screen;
    opacity: 0.05;
    top: 0;
    left: -40%;
    filter: brightness(0) invert(1);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  .card-header {
    display: flex;
    gap: 10px;

    .company-logo-image {
      width: 25px;
      height: 25px;
      margin: auto 0;
      margin-top: 4px;
    }

    .icon-btn {
      margin: auto;
      margin-right: 0;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.2;
  }

  .subtitle {
    font-size: 11px;
    opacity: 0.7;
    font-weight: 300;
  }

  .card-content {
    padding-left: 35px;

    .description {
      font-size: 13px;
      font-weight: 300;
      opacity: 0.7;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      height: 100px;
    }

    &.details {
      display: flex;
      justify-content: space-between;

      .detail {
        .label {
          font-size: 10px;
          font-weight: 300;
          opacity: 0.5;
        }

        .users-list {
          margin-left: 30px !important;
          transition: 0.25s cubic-bezier(0.43, 0.195, 0.02, 1) all;
        }
      }
    }
  }
}

// globally used items (could be in a different common scss file)
.company-logo-image {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.metric {
  color: white;
  font-size: 18px;
  opacity: 0.8;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.flex {
    display: flex;

    div,
    span {
      margin: auto;
    }
  }

  span {
    font-size: 25px;
    background-image: linear-gradient(rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0.1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: 600;

    &.currency {
      font-family: $primary-font;
    }

    &.decimal {
      font-size: 18px;
      opacity: 0.5;
    }

    &.small {
      -webkit-text-fill-color: unset;
      background-clip: unset;
      background-image: unset;
      font-weight: 300;
      font-size: 14px;
      opacity: 0.8;
    }

    &.category {
      background-image: unset;
      -webkit-text-fill-color: unset;
      -webkit-background-clip: unset;
      font-weight: 300;
      padding: 4px 10px;
      margin: 5px 0;
      margin-right: 5px;
      border: 1px solid;
      border-color: #ffff;
      cursor: pointer;
      transition: 0.25s ease all;
      font-size: 8px;
      border-radius: 20px;
      opacity: 0.5;

      &:hover {
        background-color: #ffff;
        color: #181818;
      }
    }
  }
}

.users-list {
  display: flex;
  gap: -5px;

  .user-image {
    transition: 0.25s ease all;
    width: 20px;
    height: 20px;
    background-image: url(https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg);
    z-index: 1;

    &.not-active {
      opacity: 0.5;
    }

    &:last-of-type {
      transform: translateX(-50%);
      z-index: 2;
    }

    &:first-of-type {
      transform: translateX(50%);
      z-index: 0;
    }

    &.nbr {
      background: rgb(21 21 21 / 72%);
      backdrop-filter: blur(5px);
      font-size: 10px;
      font-weight: 300;
      color: #ffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.confirmation {
    gap: 15px;
    margin-top: 25px;
    flex-wrap: wrap;

    .user {
      margin: 0 !important;

      .user-image {
        transform: unset !important;
      }

      .user-info {
        display: flex;
        flex-direction: column;
        gap: 7px;

        .user-name {
          font-size: 11px;
        }
      }
    }
  }

}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid white;
  background-size: cover;
  background-position: center;
  background-color: #e1e1ee;
  background-image: url(../images/empty.webp);

  &.menu {
    min-width: 40px;
  }
}

//! Temp design
.user-name {
  margin-right: 4px;
}

.icon-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  color: white;
  transition: 0.25s ease all;
  background: linear-gradient(180deg, #303030 0%, hsl(0, 0%, 7%) 100%);
  border-radius: 50%;
  background: linear-gradient(180deg, #303030 0%, hsl(0, 0%, 7%) 100%);

  i {
    font-size: 18px;
    font-weight: 500;
    text-shadow: 2px 2px 5px black;
    transition: 0.75s ease all;
  }
}

.members-cards-container {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;

  &.scroll {
    height: 330px;
    overflow-y: scroll;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .member-card {
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    width: 150px !important;
    height: fit-content;
    box-sizing: border-box;
    margin-right: 10px;
    margin-bottom: 10px;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;

    &.selected {
      .image-overlay {
        border: 2px solid rgb(204, 165, 123);
      }

      .overlay {
        h5 {
          color: #cca57bb3;
          font-weight: 700;
        }
      }
    }

    .selected {
      display: flex;
      justify-content: end;
      margin-top: 5px;
      opacity: 0.8;
      position: absolute;
      top: 12px;
      left: 12px;
      text-shadow: 1px 2px 5px #2c2013;
      animation: appear .5s ease-in-out;
      color: #dcc2a5;

      i {
        margin: auto 0;
        font-size: 13px;
      }

      div {
        margin: auto 0;
        margin-left: 5px;
        font-size: 10px;
      }
    }

    &:hover {
      transform: scale(1.05);
    }

    .image-overlay {
      box-sizing: border-box;
      width: 100%;
      height: 150px;
      // position: absolute;
      left: 0;
      top: 0;
      background-image: url(../images/empty.jpg);
      background-position: center;
      background-size: cover;
      border-radius: 10px;
      -webkit-mask-image: linear-gradient(to bottom,
          rgb(0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 90%);
      transition: 0.2s ease-in-out all;
    }

    .overlay {
      box-sizing: border-box;
      width: 100%;
      //height: 100%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      padding: 10px;
      border-radius: 10px;
      //position: absolute;
      top: unset;
      //transform: translateY(40%);

      h4,
      h5 {
        margin: 0;
      }

      h4 {
        font-size: 10px;
        font-weight: 300;
        opacity: 0.8;
      }

      h5 {
        font-size: 13px;
        position: absolute;
        bottom: 12px;
        transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;

        font-weight: 300;
        font-size: 11px;
        left: 0;
        text-align: center;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.add-member {
      .image-overlay {
        background-image: unset;
        background: radial-gradient(121.65% 100% at 32.68% 0,
            rgba(59, 57, 57, 0.93) 0,
            rgba(33, 33, 33, 0.68) 32.49%,
            rgba(60, 65, 85, 0) 51.34%),
          radial-gradient(91.41% 43.04% at 50% 0,
            rgba(33, 33, 33, 0.5568627451) 20.67%,
            rgba(26, 28, 36, 0) 100%),
          radial-gradient(69.96% 25.89% at 50% 100%,
            rgba(33, 33, 33, 0.5568627451) 22.77%,
            rgba(19, 21, 27, 0) 100%);
        box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
          inset -1.2000000477px 0 rgb(255 255 255 / 4%),
          inset 0 1.2000000477px rgb(255 255 255 / 10%),
          inset 0 0.6000000238px rgb(255 255 255 / 10%),
          rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
        -webkit-mask-image: unset;

        display: flex;
        justify-content: center;
        align-items: center;
        color: #8a8a8a;
        margin: 0 auto;

        .plus-sign {
          margin: auto;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          color: white;
          background-color: #3d3d3e;
          border-radius: 100px;
          box-shadow: rgba(14, 16, 16, 0.82) 0px 2px 4px 0px,
            rgba(24, 24, 24, 0.92) 0px 2px 16px 0px;
          box-shadow: rgb(0 0 0) 0px 2px 4px 0px, rgb(24 24 24) 0px 2px 16px 0px;
          transition: 0.25s ease all;
          background: linear-gradient(180deg, #464343 0%, rgb(24 24 24) 100%);
          box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px 0px,
            rgb(24 24 24 / 50%) 0px 2px 16px 0px;

          i {
            font-size: 25px;
            font-weight: 500;
            text-shadow: 2px 2px 5px black;
            transition: 0.75s ease all;
          }
        }

        p {
          font-size: 13px;
        }
      }
    }
  }
}

.stat {
  padding-top: 2rem;
  animation: appear 0.5s ease-in-out;

  h3 {
    font-size: 35px;
    background-image: linear-gradient(rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0.1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: 600;
    margin: 0;
    opacity: 1;
    font-family: $secondary-font;
    line-height: 1.2;

    span {
      background-image: linear-gradient(rgb(255, 255, 255) 50%,
          rgba(255, 255, 255, 0.1) 100%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      opacity: 0.8;
    }

    .currency {
      font-family: $primary-font;
    }

    .secondary {
      font-size: 16px;
      opacity: 0.5;
      color: rgba(255, 255, 255, 0.4);
      margin-left: 5px;
    }
  }

  p {
    font-size: 13px;
    margin: 0;
    line-height: 1.2;
    font-weight: 300;
    width: 85%;
    transition: 0.25s ease all;
    min-height: 35px;
    color: rgb(141 141 141);
    opacity: 1;

    &.show-more {
      margin-top: 5px;
      margin-right: 0;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
      opacity: 0.8;
      cursor: pointer;
      min-height: unset;
      width: fit-content;
      color: rgb(255, 255, 255);
      opacity: 0.5;

      &:hover {
        color: #cca57b;
        opacity: 1;
      }
    }

    i {
      font-size: 13px;
      margin: auto;
      transform: translateY(1.5px);
    }

    span {
      color: #cca57b;
      color: rgba(255, 255, 255, 0.8);
      font-weight: 500;
    }
  }

  &.go-back {
    cursor: pointer;
    opacity: 0.8;
    transition: 0.5s ease all;

    &:hover {
      opacity: 1;
    }

    p {
      color: rgb(255 255 255);
      margin: 0;
    }
  }
}

.button {
  width: fit-content;
  margin: auto;
  margin-right: 0;
  font-size: 12px;
  font-weight: 500;
  color: #ffffffc2;
  padding: 15px 35px;
  border-radius: 15px;
  max-width: 300px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
  background: radial-gradient(113.0392921192% 100% at 50.0000101065% 0%,
      #181818 0%,
      rgb(25, 25, 25) 0.0099999997%,
      hsla(0, 0%, 3%, 0.81) 100%);
  box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
    inset -1.2000000477px 0 rgb(255 255 255 / 4%),
    inset 0 1.2000000477px rgb(255 255 255 / 10%),
    inset 0 0.6000000238px rgb(255 255 255 / 10%),
    rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
  border-radius: 15px 15px 5px 15px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  margin-bottom: 15px;

  &:hover {
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 -1.2000000477px rgb(255 255 255 / 10%),
      inset 0 -0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    border: 1px solid;
    background: linear-gradient(167deg, #2c2d30, #ffffff00);
    border-color: #aaa7a7a6;
  }
}

.percentage-metric {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;
  pointer-events: none;

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }

  h3 {
    font-size: 25px;
    background-image: linear-gradient(rgb(255, 255, 255) 50%,
        rgba(255, 255, 255, 0.1) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: 600;
    margin: auto;

    span {
      background-image: linear-gradient(rgb(255, 255, 255) 50%,
          rgba(255, 255, 255, 0.1) 100%);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      font-weight: 600;
      opacity: 0.5;
    }
  }

  h4 {
    font-size: 10px;
    font-weight: 300;
    color: #ffff;
    margin: 0;
    opacity: 0.5;
    margin: auto;
    text-align: center;
    margin-bottom: 5px;
  }

  svg {
    height: 150px;
    width: 150px;
    transform: rotate(0deg);
  }

  #progress,
  #track {
    fill: transparent;
  }

  #progress {
    stroke: #cca57b;
    stroke-width: 5px;
    stroke-linecap: round;
    transition: 0.25 ease all;
    box-shadow: 0 0 20px 10px rgb(204 165 123 / 10%);
    filter: drop-shadow(0px 0px 4px rgb(204 165 123));
    transition: 0.5s ease all;
  }

  #track {
    stroke-width: 5px;
    stroke: #4a4140;
  }
}

.teams-cards-container {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}

.team-card {
  width: 350px;
  height: 250px;
  border-radius: 25px;
  background: radial-gradient(113.0392921192% 100% at 50.0000101065% 0%,
      #181818 0%,
      rgb(25, 25, 25) 0.0099999997%,
      hsla(0, 0%, 3%, 0.81) 100%);
  box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
    inset -1.2000000477px 0 rgb(255 255 255 / 4%),
    inset 0 1.2000000477px rgb(255 255 255 / 10%),
    inset 0 0.6000000238px rgb(255 255 255 / 10%),
    rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
  padding: 20px;
  box-sizing: border-box;
  padding: 30px;
  position: relative;
  z-index: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;

  &.card-add {
    cursor: pointer;
    .overlay {
      opacity: 0.1 !important;
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  &.not-added {
    opacity: 0.25;

    &:hover {
      opacity: 0.8;
    }
  }

  &:hover {
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 1.2000000477px rgb(255 255 255 / 10%),
      inset 0 0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 98%) 0px 19px 38px, rgb(0 0 0 / 92%) 0px 15px 12px;

    .overlay {
      opacity: 0.8;
    }
  }

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../images/05.jpg);
    background-size: cover;
    border-radius: 25px;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.8s cubic-bezier(0.43, 0.195, 0.02, 1) all;
  }

  .team-card-header {
    display: flex;
    justify-content: space-between;

    .title {
      width: fit-content;

      p {
        margin: 0;
        color: #e2e8ff;
        opacity: 0.8;
        font-weight: 300;
        font-size: 11px;
      }

      h3 {
        margin: 0;
        color: #e2e8ff;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 1.2;
        text-shadow: 0 0 20px rgb(194 194 194 / 70%);
        background-image: linear-gradient(#e2e8ff 20%, #e2e8ff8f 100%);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        font-size: 30px;
      }
    }

    .team-card-header-options {
      display: flex;
      width: fit-content;
      height: fit-content;
      box-sizing: border-box;
      margin: auto 0;
      padding: 5px 10px;
      background: #262424;
      border-radius: 20px;
      gap: 5px;
      margin: 0;
      .icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .team-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 5px;

    .info-section {
      display: flex;
      justify-content: space-between;

      .stats {
        width: fit-content;

        label {
          font-size: 10px;
          font-weight: 300;
          opacity: 0.8;
          text-shadow: 0 1px 2px rgb(0 0 0);
        }

        h4 {
          margin: 0;
          font-weight: 300;
          font-size: 25px;
          width: fit-content;

          span {
            font-size: 13px;
            opacity: 0.6;
          }

          i {
            font-size: 14px;
            opacity: 0.9;
            margin: 0 5px;
          }

          .trending {
            opacity: 0.8;
            font-size: 9px;
          }
        }

        h5 {
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          width: fit-content;
        }
      }
    }

    .users-list {
      margin: 0 auto;
      margin-right: -17px !important;
    }

    .add-button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #8a8a8a;
      margin: 0 auto;
      opacity: 1;
      transform: translateY(10%);

      .plus-sign {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        color: white;
        background-color: #3d3d3e;
        border-radius: 100px;
        box-shadow: rgba(14, 16, 16, 0.82) 0px 2px 4px 0px,
          rgba(24, 24, 24, 0.92) 0px 2px 16px 0px;
        box-shadow: rgb(0 0 0) 0px 2px 4px 0px, rgb(24 24 24) 0px 2px 16px 0px;
        transition: 0.25s ease all;
        background: linear-gradient(180deg, #464343 0%, rgb(24 24 24) 100%);
        box-shadow: rgb(0 0 0 / 50%) 0px 2px 4px 0px,
          rgb(24 24 24 / 50%) 0px 2px 16px 0px;

        i {
          font-size: 25px;
          font-weight: 500;
          text-shadow: 2px 2px 5px black;
          transition: 0.75s ease all;
        }
      }

      p {
        font-size: 13px;
      }
    }
  }
}

.modal-group {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 100;

  .modal-container {
    margin: auto;
    width: 50%;
    height: 80%;

    &.confirmation {
      //width: 25vw;
      height: 50vh;
    }

    &.preview {
      margin-top: 5vh;

      .modal {
        height: 90vh;

        h3 {
          font-size: 18.72px;
          font-weight: 600;
        }

        img {
          width: 100%;
          border-radius: 4px;
          background: rgba(78, 84, 95, 0.5803921569);
        }

        .team-card  {
          width: 45% !important;
          height: 150px;
          cursor: pointer;
        }

        .software-selection {
          display: flex;
          gap: 10px;
          align-items: center;
          font-size: 11px;
          max-width: unset !important;
          .icon {
            width: 30px;
            height: 30px;
            background-position: center;
            background-size: contain;
          }
        }
      }
    }

    @media only screen and (max-width: 475px) {
      width: 90vw !important;

      .modal {
        padding: 40px 30px;
      }
    }
  }

  .modal-overlay {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #1010118a;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.5s cubic-bezier(0.43, 0.195, 0.02, 1);
  }

  .modal {
    margin: auto;
    width: 100%;
    height: 90%;
    box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
      inset -1.2000000477px 0 rgb(255 255 255 / 4%),
      inset 0 1.2000000477px rgb(255 255 255 / 10%),
      inset 0 0.6000000238px rgb(255 255 255 / 10%),
      rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
    background: radial-gradient(113.0392921192% 100% at 50.0000101065% 0%,
        #181818 0%,
        rgb(25, 25, 25) 0.0099999997%,
        hsla(0, 0%, 3%, 1) 100%);
    border-radius: 15px;
    animation: appear 0.75s cubic-bezier(0.43, 0.195, 0.02, 1);
    border: 1px solid rgba(255, 255, 255, 0.05);
    color: #e2e8ff;
    padding: 40px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    padding-bottom: 0;

    .titles {
      h3 {
        font-weight: 400;
        margin: 0;
        line-height: 1.5;
      }

      p {
        font-size: 13px;
        opacity: 0.7;
        margin: 0;
        font-weight: 300;
      }
    }

    .modal-content {
      animation: appear-left-right 0.8s cubic-bezier(0.43, 0.195, 0.02, 1);
      padding: 30px 0;
      height: 80%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      &.add-team-modal {
          display: flex;
          justify-content: space-between;
          form {
            width: 50%;
          }
          .team-card  {
            width: 50%;
          }
          .half {
            width: 50%;

            form {
              width: 100%;
            }

            img {
              width: 100%;
            }
          }
      }

      .search-bar-container {
        display: flex;
        justify-content: space-between;

        p {
          opacity: 0.7;
          margin: auto 0;
          font-weight: 300;
          font-size: 13px;
          line-height: 1;
          height: fit-content;
        }

        .search-bar {}

        .select-all-container {
          display: flex;
          cursor: pointer;
          transition: 0.25s ease all;

          i {
            font-size: 20px;
            margin: auto 0;
          }

          p {
            font-size: 10px;
            margin: auto;
            margin-left: 2px;
          }

          &:hover {
            color: rgb(204, 165, 123);
          }
        }
      }

      .search-content,
      .teams-content {
        display: flex;
        flex-wrap: wrap;
        max-height: 100%;
        padding: 15px 0;
        overflow-y: scroll;
        gap: 10px;
        scroll-behavior: smooth;
        box-sizing: border-box;
        padding-bottom: 100px;

        &::-webkit-scrollbar {
          display: none;
        }

        .mini-software-card {
          border-radius: 10px;
          box-sizing: border-box;
          overflow: hidden;
          border: 1.5px solid rgba(109, 105, 105, 0.4);
          background: rgba(78, 84, 95, 0.3);
          width: calc(33.333% - 10px);
          padding: 15px 10px;
          background: radial-gradient(121.65% 100% at 32.68% 0,
              rgba(59, 57, 57, 0.93) 0,
              rgba(33, 33, 33, 0.68) 32.49%,
              rgba(60, 65, 85, 0) 51.34%),
            radial-gradient(91.41% 43.04% at 50% 0,
              rgba(33, 33, 33, 0.5568627451) 20.67%,
              rgba(26, 28, 36, 0) 100%),
            radial-gradient(69.96% 25.89% at 50% 100%,
              rgb(33, 33, 33) 22.77%,
              rgb(19, 19, 21) 100%);
          box-shadow: rgb(38 38 38) 0px 14px 28px, rgb(0 0 0) 0px 10px 10px;
          padding: 15px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          transition: 0.8s ease all;
          height: 175px;

          .alert {
            color: rgb(204, 165, 123);
          }

          &.used {
            opacity: 0.5;
            cursor: no-drop;
          }

          &.selected {
            border-color: rgb(204, 165, 123);

            .alert {
              color: rgb(204, 165, 123);
            }
          }

          .selected {
            display: flex;
            justify-content: end;
            margin-top: 5px;
            opacity: 0.8;

            i {
              margin: auto 0;
              font-size: 13px;
            }

            div {
              margin: auto 0;
              margin-left: 5px;
              font-size: 10px;
            }
          }

          .titles {
            display: flex;

            .company-logo-image {
              width: 18px;
              height: 18px;
              margin: auto 0;
            }

            h5 {
              font-size: 15px;
              margin: auto;
              margin-left: 5px;
            }
          }

          p {
            margin: 0;
            margin-top: 9px;
            font-size: 10px;
            opacity: 0.9;
            font-weight: 300;
            display: -webkit-box;
            -webkit-line-clamp: 10;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 80%;
          }
        }

        .team-card {
          width: 32%;

          &.selected {
            background: rgb(204 165 123 / 100%);
            user-select: none;

            .overlay {
              opacity: 0.6;
              box-shadow: 0 0 7px rgb(204 165 123 / 100%);
            }
          }

          h3 {
            font-size: 20px;
          }

          .selected {
            display: flex;
            margin-top: 5px;
            opacity: 0.8;
            justify-content: start;
            animation: fadeIn 0.4s forwards ease-in-out;

            i {
              margin: auto 0;
              font-size: 15px;
            }

            div {
              margin: auto 0;
              margin-left: 5px;
              font-size: 12px;
            }
          }
        }
      }

      .list {
        .software-container {
          margin-top: 15px;

          .titles {
            display: flex;
            align-items: center;
            gap: 10px;

            .company-logo-image {
              width: 25px;
              height: 25px;
            }

            h5 {
              margin: 0;
            }
          }

          .users {
            .user {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-top: 16px;
              margin-left: 15px;

              .avatar {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .next-btn-container {
      width: 100%;
      padding: 15px 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #1818184a;
      z-index: 1;
      backdrop-filter: blur(5px);
      display: flex;
      justify-content: end;
      box-sizing: border-box;
      animation: appear .5s ease-in-out;

      .next-btn {
        font-size: 13px;
        border-radius: 3.626px;
        border: 1px solid rgba(109, 105, 105, 0.4);
        padding: 6px 20px;
        font-weight: 300;
        color: #e2e8ff;
        border-color: rgba(204, 165, 123, 0.5490196078);
        background: rgb(204, 165, 123);
        cursor: pointer;
        transition: 0.4s cubic-bezier(0.43, 0.195, 0.02, 1) all;
        margin-left: 7px;

        &.previous {
          background: #1c1d1f;
        }

        &:hover {
          background: rgb(204, 165, 123);
          box-shadow: 0 0 20px rgb(204 165 123 / 70%);
        }
      }
    }
  }

  .steps-container {
    display: flex;
    margin: auto;
    width: 80%;
    justify-content: space-around;
    margin-top: 3.5%;
    animation: appear 0.5s cubic-bezier(0.43, 0.195, 0.02, 1);
    position: relative;

    .step {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-sizing: border-box;
      overflow: hidden;
      border: 1px solid rgba(109, 105, 105, 0.4);
      cursor: pointer;
      margin-right: 7.25px;
      font-size: 13px;

      box-shadow: inset 1.2000000477px 0 rgb(255 255 255 / 4%),
        inset -1.2000000477px 0 rgb(255 255 255 / 4%),
        inset 0 -1.2000000477px rgb(255 255 255 / 10%),
        inset 0 -0.6000000238px rgb(255 255 255 / 10%),
        rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
      border-color: rgba(204, 165, 123, 0.5490196078);
      background: rgba(31, 33, 37, 0.4588235294);
      background: #1c1d1f;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 1s ease all;

      &.active {
        background: rgba(204, 165, 123, 1);
        box-shadow: 0 0 20px rgb(204 165 123 / 70%);
        color: white;
      }
    }

    &::before {
      content: '';
      width: 110%;
      height: 2px;
      opacity: 1;
      background-image: linear-gradient(to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0));
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: -5%;
      z-index: -1;
    }
  }
}

.search-bar {
  padding: 10px;
  border-radius: 5px;
  border: 1.5px solid #afa9a980;
  background: linear-gradient(167deg, #2c2d30, rgba(255, 255, 255, 0));
  width: 20%;
  border-color: #ffffff3b;
  border-width: 1px;
  display: flex;
  justify-content: space-between;
  border-color: rgba(109, 105, 105, 0.4);
  transition: 0.75s ease all;
  min-width: 200px;

  &.alternative {
    background: none;
    border: none;
    border-bottom: 1.5px solid;
    border-radius: 0;
    border-color: rgba(175, 169, 169, 0.5019607843);

    &.focus {
      border-color: rgba(255, 255, 255, 0.733);

      i {
        color: rgba(255, 255, 255, 0.733);
      }
    }
  }

  i {
    margin: auto;
    margin-left: 0;
    margin-right: 10px;
    transition: 0.75s ease all;
  }

  input[type='text'] {
    font-size: 10px;
    margin: auto;
    margin-left: 0;
    color: #ffffffda;
    background: transparent;
    outline: none;
    border: none;
    font-family: $secondary-font;
    width: 100%;

    &::placeholder {
      color: #8a8a8a;
      text-transform: capitalize;
    }
  }
}

// providers cards integration

$font55: 45px;
$font30: 20px;
$font25: 16px;
$font22: 14px;
$font20: 12px;
$font18: 10px;

.providers-container {
  // padding-top: 3rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .providers-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'providers-cards selected-provider';
    column-gap: 5rem;
    // padding-right: 5rem;
    width: 100%;

    .providers-cards {
      width: 100%;
      grid-area: providers-cards;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 2rem;

      .provider-card {
        aspect-ratio: 16/9;
        background-color: #4a4140;
        border-radius: 20px;
        border: 2px solid #767272;
        height: 80%;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        box-shadow: 0 0 70px rgba(0, 0, 0, 0.56);
        background: radial-gradient(121.65% 100% at 32.68% 0,
            rgba(59, 57, 57, 0.93) 0,
            rgba(33, 33, 33, 0.68) 32.49%,
            rgba(60, 65, 85, 0) 51.34%),
          radial-gradient(91.41% 43.04% at 50% 0,
            rgba(33, 33, 33, 0.5568627451) 20.67%,
            rgba(26, 28, 36, 0) 100%),
          radial-gradient(69.96% 25.89% at 50% 100%,
            rgb(33, 33, 33) 22.77%,
            rgb(19, 19, 21) 100%);
        box-shadow: rgba(0, 0, 0, 0.9) 0px 19px 38px,
          rgba(0, 0, 0, 0.52) 0px 15px 12px;
        border: 1px solid rgba(138, 138, 138, 0.4392156863);

        .card-image {
          width: 40%;
          height: 50%;
          opacity: 0.3;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        .card-info {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: #fffef1;
          text-align: end;

          span {
            text-transform: uppercase;
            font-weight: 300;
            font-size: $font18;
            opacity: 0.57;
          }

          .card-title {
            font-weight: 500;
            font-size: $font25;
            text-transform: capitalize;
          }

          .connect-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10%;
            margin-top: auto;
            font-size: 12px;
            font-weight: 400;
            color: #8a8a8a;
          }
        }

        &.selected {
          background: #cca57b;
          box-shadow: 0 0 20px 10px rgba(204, 165, 123, 0.3);
          color: white;

          .card-image {
            opacity: 1;
            filter: brightness(0.5);
          }

          .card-info {
            span {
              opacity: 0.92;
            }

            .card-title {
              opacity: 0.95;
            }

            .connect-button {
              opacity: 0.78;
              color: white;

              i::before {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }

    .selected-provider {
      grid-area: selected-provider;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-image: url('https://res.cloudinary.com/dg91u71x5/image/upload/v1700171627/nrl8chocir7yiltbbgyy.png');
      background-position: top left;
      background-size: 80% 60%;
      background-repeat: no-repeat;
      padding: 2rem;
      padding-bottom: 1rem;
      background-color: rgba(74, 65, 64, 0.65);
      border-radius: 20px;
      border: 2px solid #767272;
      color: #fffef1;
      box-shadow: 0 0 70px rgba(0, 0, 0, 0.56);
      margin-right: 3rem;

      .provider-image {
        height: 70px;
        width: 70px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      .chip-image {
        opacity: 0.75;
        height: 50px;
        width: 60px;
        background-image: url('https://res.cloudinary.com/dg91u71x5/image/upload/v1697986238/nuw28zafd5tgm9qymslb.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .row {
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-family: 'Rajdhani';
        align-items: center;

        .label {
          text-transform: uppercase;
          font-size: 12px;
          font-family: Inter;
          opacity: 0.56;
          font-weight: 300;
        }

        .value {
          font-weight: 500;
          font-size: 19px;
          text-transform: capitalize;

          i {
            margin-left: 0.5rem;
          }
        }
      }

      .row:first-child {
        margin-bottom: 2rem;
        font-family: 'Inter';

        .label {
          font-size: $font18;
        }

        .value {
          font-size: $font25;
        }
      }

      .row:last-child {

        .label,
        .value {
          font-family: 'Inter';
          font-size: $font22;
          text-transform: capitalize;
          opacity: 0.87;
        }

        .connect-button {
          cursor: pointer;
          font-family: 'Inter';
          color: white;

          i {
            opacity: 0.7;
          }
        }
      }

      .business-info {
        display: flex;
        gap: 0.3rem;
        align-items: center;
      }

      .connect-button {
        display: flex;
        align-items: center;
        gap: 10%;
      }
    }

    .selected-provider,
    .provider-card {
      background-color: #212121;
      border: 1px solid #8a8a8a70;
      color: #bababa;
      box-shadow: rgb(0 0 0 / 90%) 0px 19px 38px, rgb(0 0 0 / 52%) 0px 15px 12px;
      transition: 0.25s ease all;
      cursor: pointer;

      &:hover {
        box-shadow: rgb(0 0 0 / 90%) 0px 4px 16px, rgb(2 2 2 / 90%) 0px 8px 24px,
          rgba(0, 0, 0, 0.8) 0px 16px 56px;
      }
    }
  }
}

// add widgets modal
.modal-container.add-widgets {
  .dashboard-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: fit-content;
    padding: 0px;

    .container {
      height: fit-content;
      width: 34%;
    }

    .card {
      width: 100%;
      min-width: 270px;
      overflow: hidden;
      cursor: pointer;
    }
  }
}

.aligned {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text-input,
.email-input, 
.text-area-input,
select {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.8549019608);
  background: transparent;
  outline: none;
  border: none;
  font-family: $secondary-font;
  padding: 10px;
  width: 250px;
  border: 1px solid #ffffff45;
  border-radius: 3px;
  box-shadow: inset 1.2000000477px 0 rgba(255, 255, 255, 0.04), inset -1.2000000477px 0 rgba(255, 255, 255, 0.04), inset 0 -1.2000000477px rgba(255, 255, 255, 0.1), inset 0 -0.6000000238px rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.9) 0px 4px 38px, rgba(0, 0, 0, 0.52) 0px 2px 12px;
  background: rgba(31, 33, 37, 0.4588235294);
  border-color: #ffffff61;
  margin: auto 0;
  transition: all .7s cubic-bezier(.43,.195,.02,1);
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;
  resize: none;

  &::placeholder {
    color: #8a8a8a;
  }

  &:focus {
    border-color: rgba(204, 165, 123, 0.5490196078);
  }

  &.selection {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 36px;

    &.open {
      border-color: rgba(204, 165, 123, 0.5490196078);
    }

    .icon {
      opacity: .8;
      font-size: 15px;
    }
    .dropdown-menu {
      width: 100%;
      bottom: -6px;
      transform: translateY(100%);
      box-sizing: border-box;
      max-width: 500px;
      min-width: unset;
      left: 0;
      border-radius: 2px;

      .dropdown-item {
        &:hover, &.selected {
          background-color: #cca57b;
        }
      }
    }
  }
}

.text-area-input {
  height: 200px;
}

.invite-user-container {
  display: flex;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2rem;
  gap: 15px;
}

.form-button {
  align-items: center;
  animation: appear 1s ease;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, .5));
  font-weight: 400;
  justify-content: center;
  padding: 5px 30px;
  transition: all .7s cubic-bezier(.43,.195,.02,1);
  -webkit-user-select: none;
  user-select: none;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px;
  box-sizing: border-box;
  height: 32.8px;
  border-color: rgba(204, 165, 123, 0.5490196078);
  border: 1px solid rgba(109, 105, 105, 0.4);
  background: rgba(78, 84, 95, 0.3);
  color: rgba(255, 255, 255, 0.723);

  color: #d28a42;
  border-color: rgba(234, 150, 26, .529);
  background: rgba(66, 57, 50, .549);

  &:hover {
    color: #d28a42;
    background: rgba(66, 57, 50, .9);
  }

  i {
    margin: auto;
    margin-bottom: 5px;
    font-size: 10px;
    margin-left: 4px;
  }
}

.empty-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1rem 0;
  gap: 2px;
  opacity: .7;
  font-size: 14px;
  flex-direction: column;
  font-style: italic;
  color: rgb(204, 165, 123);

  i {
    font-size: 61px;
    text-shadow: 0 0 20px rgba(204, 165, 123, 0.7);
  }

  p {
    margin: 0;
    font-size: 11px;
  }
}

.scroll-shadows {
  position: relative;

  &::before {
    content: '';
    background: linear-gradient(to bottom, #101011, rgba(255, 255, 255, 0));
    width: 100%;
    height: 34px;
    position: absolute;
    box-sizing: border-box;
    z-index: 100;
    bottom: 0;
    top: unset;
    transform: translateY(98%);
  }
}

.form-input-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    font-size: 11px;
  }
}

.double-input {
  display: flex;
  gap: 10px;
  width: 100%;
}

.dropdowns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dropdown-menu {
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  background-color: #343437;
  z-index: 5;
  cursor: pointer;
  border-radius: 7px;
  background: rgb(31 33 37 / 80%) !important;
  backdrop-filter: blur(4px);
  animation: fadeIn 0.2s forwards ease-in-out;

  .dropdown-item {
    color: white;
    font-size: 10px;
    font-weight: 300;
    box-sizing: border-box;
    padding: 10px;
    width: fit-content;

    justify-content: center;
    display: flex;
    align-items: center;
    gap: 10px;
    opacity: 0.8;
    user-select: none;

    i {
      font-size: 14px !important;
      margin: 0;
    }
  }
}

.button {
  .dropdown-menu {
    top: 115%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    text-align: center;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: no-drop !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(50%);
}

.breadcrumb {
  display: flex;
  gap: 5px;

  a {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    opacity: .7;
    transition: .4s ease-in-out all;

    i {
      font-size: 22px;
      color: white;
      transform: translateY(1px);
    }

    &:hover {
      opacity: 1;
    }
  }

}

.scrollable {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.h110 {
  height: 110%;
}

.no-width { 
  width: 1% !important;

  .dropdown-menu {
    left: unset !important;
    right: 0  !important;
  }
}

.options { 

  .dropdown-menu {
    left: unset !important;
    right: 0  !important;
  }
}